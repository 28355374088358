import React, { useEffect, useState } from "react";
import { BrowserRouter as Router, Routes, Route, useLocation } from "react-router-dom";
import { auth } from "./firebase";
import { User as FirebaseUser } from "firebase/auth";
import Chat from "./components/Chat";
import SignIn from "./components/SignIn";
import Profile from "./components/Profile";
import WelcomeScreen from "./components/WelcomeScreen";
import SharedChat from "./components/SharedChat";
import HorseGame from "./components/HorseGame";
import HorseWeightCheck from "./components/HorseWeightCheck";
import Quiz from "./components/Quiz/Quiz";
import BottomTabBar from "./components/BottomTabBar";

// Wrapper component to handle navigation logic
const AppContent: React.FC = () => {
  const [user, setUser] = useState<FirebaseUser | null>(null);
  const [loading, setLoading] = useState(true);
  const location = useLocation();

  useEffect(() => {
    const unsubscribe = auth.onAuthStateChanged((user) => {
      setUser(user);
      setLoading(false);
    });

    return () => unsubscribe();
  }, []);

  if (loading) {
    return (
      <div className="flex justify-center items-center h-screen">
        <div className="animate-spin rounded-full h-12 w-12 border-b-2 border-blue-600"></div>
      </div>
    );
  }

  return (
    <div className="min-h-screen bg-gray-50 flex flex-col">
      <main className="flex-grow">
        <Routes>
          <Route path="/" element={user ? <Chat /> : <WelcomeScreen />} />
          <Route path="/signin" element={<SignIn />} />
          <Route path="/profile" element={<Profile user={user} />} />
          <Route path="/shared/:chatId" element={<SharedChat />} />
          <Route path="/game" element={<HorseGame />} />
          <Route path="/weight-check" element={<HorseWeightCheck />} />
          <Route path="/quiz" element={<Quiz />} />
        </Routes>
      </main>
      {user && !location.pathname.startsWith('/shared/') && <BottomTabBar />}
    </div>
  );
};

const App: React.FC = () => {
  return (
    <Router>
      <AppContent />
    </Router>
  );
};

export default App;
