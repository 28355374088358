import React, { useState, useRef, useEffect } from 'react';
import { ChatMessage, sendMessage, getChatSuggestions } from '../services/chat';
import ReactMarkdown from 'react-markdown';
import { Send, Plus, MessageCircle, Menu, X, ArrowRight, Trash2, Share2, AlertTriangle } from 'lucide-react';
import { auth, db } from '../firebase';
import { 
  collection, 
  query, 
  where, 
  orderBy, 
  getDocs, 
  addDoc, 
  updateDoc,
  doc,
  serverTimestamp,
  getDoc,
  deleteDoc
} from 'firebase/firestore';
import LoginForm from './LoginForm';
import { summarizeHorseProfile } from './Profile';
import { Link } from 'react-router-dom';
import { trackEvent, trackError, trackPageView } from '../services/analytics';
import { getRemainingCredits, useCredit, initializeUserCredits } from '../services/credits';

// Interface for chat session
interface ChatSession {
  id: string;
  title: string;
  lastMessage: string;
  createdAt: Date;
  updatedAt: Date;
  isShared?: boolean;
  shareId?: string;
}

// Add interface for message with suggested questions
interface MessageWithSuggestions extends ChatMessage {
  suggestedQuestions?: string[];
}

// Add this near the other interfaces
interface ChatSuggestion {
  category: string;
  text: string;
}

// Add new interfaces after the existing ones
interface UserProfile {
  horseDescription: string;  // "Alf ist ein siebenjähriger Hengst..."
  horseName: string;         // "Alf"
  ownerInfo: string;        // "Reitet bis L springen"
  ownerName: string;        // "Tim"
  updatedAt: string;        // "2024-12-11T08:26:27.738Z"
  horseBreed?: string;      // Added missing field
  horseAge?: string;        // Added missing field
  horseHeight?: string;     // Added missing field
}

// Add interface for HorseProfile
interface HorseProfile {
  horseName: string;
  horseDescription: string;
  ownerName: string;
  ownerInfo: string;
  horseBreed: string;
  horseAge: string;
  horseHeight: string;
  updatedAt: string;
}

interface Horse {
  name: string;
  breed?: string;
  age?: number;
  // Add other horse-related fields as needed
}

// Add this function to your chat service or create it inline
const generatePersonalizedSuggestions = async (userId: string): Promise<string[]> => {
  try {
    const response = await fetch(`/api/generate-suggestions?userId=${userId}`);
    if (!response.ok) throw new Error('Failed to generate suggestions');
    const data = await response.json();
    return data.suggestions;
  } catch (error) {
    console.error('Error fetching personalized suggestions:', error);
    return [];
  }
};

// Add default system prompt
const defaultSystemPrompt = `Ich bin ein Pferde-Experte und Berater. Ich helfe dir bei allen Fragen rund um die Pferdehaltung, -pflege und -training.`;

// Track error with proper type checking
const handleError = (error: unknown, context: string) => {
  if (error instanceof Error) {
    trackError(error, context);
  } else {
    // If it's not an Error object, create one
    trackError(new Error(String(error)), context);
  }
};

// Add this function after the other helper functions
const generateSuggestedQuestions = async (message: string, horseProfile: HorseProfile | null): Promise<string[]> => {
  try {
    const response = await fetch('/api/generate-suggestions', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        message,
        userProfile: horseProfile
      }),
    });

    if (!response.ok) {
      throw new Error('Failed to generate suggestions');
    }

    const data = await response.json();
    return data.suggestions;
  } catch (error) {
    console.error('Error generating suggestions:', error);
    return horseProfile?.horseName 
      ? [
          `🏥 Wie geht es ${horseProfile.horseName} gesundheitlich?`,
          `🎯 Wie können wir ${horseProfile.horseName}'s Training verbessern?`,
          "🌟 Was sind die wichtigsten Punkte bei der täglichen Pferdepflege?"
        ]
      : [
          "🏥 Wie erkenne ich, ob es meinem Pferd gut geht?",
          "🎯 Wie kann ich die Kommunikation mit meinem Pferd verbessern?",
          "🌟 Was sind die wichtigsten Punkte bei der täglichen Pferdepflege?"
        ];
  }
};

// Add this function to load horse profile data
const loadHorseProfile = async (userId: string): Promise<HorseProfile | null> => {
  try {
    const profileRef = doc(db, "horseProfiles", userId);
    const profileDoc = await getDoc(profileRef);
    
    if (profileDoc.exists()) {
      return profileDoc.data() as HorseProfile;
    }
    return null;
  } catch (error) {
    console.error('Error loading horse profile:', error);
    return null;
  }
};

// Chat component for handling real-time AI horse care consultation
const Chat: React.FC = () => {
  const [messages, setMessages] = useState<MessageWithSuggestions[]>([]);
  const [input, setInput] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [isLoadingSuggestions, setIsLoadingSuggestions] = useState(false);
  const messagesEndRef = useRef<HTMLDivElement>(null);
  
  // New state for chat history
  const [chatSessions, setChatSessions] = useState<ChatSession[]>([]);
  const [currentSessionId, setCurrentSessionId] = useState<string | null>(null);
  const [isSidebarOpen, setIsSidebarOpen] = useState(window.innerWidth >= 768);
  const [showLoginForm, setShowLoginForm] = useState(false);
  const [suggestions, setSuggestions] = useState<string[]>([]);
  const [userProfile, setUserProfile] = useState<UserProfile | null>(null);
  const [profileChecked, setProfileChecked] = useState(false);
  const [isNoticeHidden, setIsNoticeHidden] = useState(false);
  const [personalizedSuggestions, setPersonalizedSuggestions] = useState<string[]>([]);
  const [isRefreshing, setIsRefreshing] = useState(false);
  const [isInitialLoading, setIsInitialLoading] = useState(false);
  const [shareSuccess, setShareSuccess] = useState<string | null>(null);
  const [loadingText, setLoadingText] = useState('Ich denke nach');
  const [remainingCredits, setRemainingCredits] = useState<number | null>(null);
  const [showCreditAlert, setShowCreditAlert] = useState(false);
  const [showWelcomePopup, setShowWelcomePopup] = useState(true);
  const [horseProfile, setHorseProfile] = useState<HorseProfile | null>(null);

  // Add styles for suggestion buttons
  const suggestionButtonStyle = `
    mt-2 px-4 py-2 bg-blue-50 hover:bg-blue-100 
    text-blue-600 rounded-lg text-sm
    transition-all duration-200 ease-in-out
    border border-blue-200 hover:border-blue-300
    shadow-sm hover:shadow
    transform hover:-translate-y-0.5
    cursor-pointer
    w-full text-left
  `;

  // Load chat sessions on component mount
  useEffect(() => {
    const loadChatSessions = async () => {
      if (!auth.currentUser) return;

      try {
        // Modified query to handle index requirements
        const sessionsQuery = query(
          collection(db, 'chatSessions'),
          where('userId', '==', auth.currentUser.uid),
          orderBy('updatedAt', 'desc')
        );

        const querySnapshot = await getDocs(sessionsQuery).catch(error => {
          console.error('Error fetching chat sessions:', error);
          // Check if error is due to missing index
          if (error.code === 'failed-precondition') {
            console.warn('Missing index for chat sessions query');
          }
          return null;
        });

        if (!querySnapshot) return;

        const sessions = querySnapshot.docs.map(doc => ({
          id: doc.id,
          ...doc.data(),
          // Ensure dates are properly converted
          createdAt: doc.data().createdAt?.toDate() || new Date(),
          updatedAt: doc.data().updatedAt?.toDate() || new Date()
        })) as ChatSession[];

        setChatSessions(sessions);
        
        // Load most recent chat if exists and no current session
        if (sessions.length > 0 && !currentSessionId) {
          setCurrentSessionId(sessions[0].id);
          await loadChatHistory(sessions[0].id);
        }
      } catch (error) {
        console.error('Error loading chat sessions:', error);
      }
    };

    loadChatSessions();
  }, [auth.currentUser, currentSessionId]);

  // Modified loadChatHistory with error handling
  const loadChatHistory = async (sessionId: string) => {
    try {
      const messagesQuery = query(
        collection(db, `chatSessions/${sessionId}/messages`),
        orderBy('timestamp')
      );

      const querySnapshot = await getDocs(messagesQuery);
      const chatMessages = querySnapshot.docs.map(doc => ({
        ...doc.data(),
        // Ensure timestamp is properly converted
        timestamp: doc.data().timestamp?.toDate() || new Date()
      })) as ChatMessage[];

      setMessages(chatMessages);
      setCurrentSessionId(sessionId);
    } catch (error) {
      console.error('Error loading chat history:', error);
    }
  };

  // Modified startNewChat to return the session ID
  const startNewChat = async () => {
    if (!auth.currentUser) return null;

    try {
      trackEvent('Chat', 'New Chat Started');

      // Get the system prompt with horse profile info
      const systemPrompt = await prepareSystemPrompt();

      const newSession = {
        userId: auth.currentUser.uid,
        title: 'New Chat',
        lastMessage: '',
        createdAt: serverTimestamp(),
        updatedAt: serverTimestamp(),
        systemPrompt: systemPrompt // Add the system prompt to the session
      };

      const sessionRef = await addDoc(collection(db, 'chatSessions'), newSession);
      const sessionWithId = { 
        ...newSession, 
        id: sessionRef.id,
        createdAt: new Date(),
        updatedAt: new Date()
      };
      
      setChatSessions(prev => [sessionWithId, ...prev]);
      setCurrentSessionId(sessionRef.id);
      setMessages([]);
      return sessionRef.id;
    } catch (error) {
      console.error('Error creating new chat:', error);
      handleError(error, 'startNewChat');
      return null;
    }
  };

  // Auto-scroll to bottom when new messages arrive
  const scrollToBottom = () => {
    messagesEndRef.current?.scrollIntoView({ behavior: 'smooth' });
  };

  useEffect(() => {
    scrollToBottom();
  }, [messages]);

  // Modify the handleSend function to include suggestion generation
  const handleSend = async (e: React.FormEvent) => {
    e.preventDefault();
    if (!input.trim()) return;

    try {
      if (!auth.currentUser) {
        setShowLoginForm(true);
        return;
      }

      // Check if user has credits available
      if (remainingCredits !== null && remainingCredits <= 0) {
        setShowCreditAlert(true);
        return;
      }

      // Track message sent
      trackEvent('Chat', 'Message Sent', input.length.toString());

      // Create new session if none exists
      if (!currentSessionId) {
        const newSessionId = await startNewChat();
        if (!newSessionId) {
          console.error('Failed to create new chat session');
          return;
        }
        // Wait briefly for the new session to be created
        await new Promise(resolve => setTimeout(resolve, 100));
      }

      // Ensure we have a session ID
      const sessionId = currentSessionId;
      if (!sessionId || !auth.currentUser) {
        console.error('No active session or user');
        return;
      }

      const userMessage: ChatMessage = {
        role: 'user',
        content: input,
        timestamp: new Date(),
      };

      // Update UI immediately
      setMessages(prev => [...prev, userMessage]);
      setInput('');
      setIsLoading(true);

      try {
        // Use one credit before sending message
        const creditUsed = await useCredit(auth.currentUser.uid);
        if (!creditUsed) {
          setShowCreditAlert(true);
          return;
        }

        // Update remaining credits display
        const updatedCredits = await getRemainingCredits(auth.currentUser.uid);
        setRemainingCredits(updatedCredits);

        // Save user message to Firestore
        await addDoc(
          collection(db, `chatSessions/${sessionId}/messages`),
          userMessage
        );

        // Get AI response
        const responseData = await sendMessage(input, auth.currentUser.uid, sessionId);
        
        if (!responseData) {
          throw new Error('No response from AI');
        }

        // Generate suggestions using horse profile
        const suggestedQuestions = await generateSuggestedQuestions(responseData, horseProfile);
        
        const botMessage: MessageWithSuggestions = {
          role: 'assistant',
          content: responseData,
          timestamp: new Date(),
          suggestedQuestions
        };

        // Save bot message to Firestore
        await addDoc(
          collection(db, `chatSessions/${sessionId}/messages`),
          botMessage
        );

        // Update session metadata
        await updateDoc(doc(db, 'chatSessions', sessionId), {
          lastMessage: botMessage.content.substring(0, 100) + '...',
          updatedAt: serverTimestamp(),
          // Update title with first message if it's a new chat
          ...(messages.length === 0 && { title: input.substring(0, 30) + '...' })
        });

        setMessages(prev => [...prev, botMessage]);
      } catch (error) {
        console.error('Error in chat:', error);
        // Show error message to user
        setMessages(prev => [...prev, {
          role: 'assistant',
          content: 'Entschuldigung, es ist ein Fehler aufgetreten. Bitte versuchen Sie es erneut.',
          timestamp: new Date(),
        }]);
        handleError(error, 'handleSend');
      }
    } catch (error) {
      console.error('Error handling message:', error);
      handleError(error, 'handleSend');
    } finally {
      setIsLoading(false);
    }
  };

  // Add debug logging
  useEffect(() => {
    console.log('Current session ID:', currentSessionId);
    console.log('Auth state:', !!auth.currentUser);
  }, [currentSessionId, auth.currentUser]);

  // Handler to close sidebar when clicking main content area on mobile
  const handleMainAreaClick = () => {
    if (window.innerWidth < 768 && isSidebarOpen) {
      setIsSidebarOpen(false);
    }
  };

  // Update the suggestions fetch to use our service
  useEffect(() => {
    const loadSuggestions = async () => {
      try {
        const { suggestions: loadedSuggestions } = await getChatSuggestions();
        setSuggestions(loadedSuggestions);
      } catch (error) {
        console.error('Failed to load suggestions:', error);
      }
    };

    loadSuggestions();
  }, []);

  // Update profile check and personalized suggestions
  useEffect(() => {
    const checkUserProfile = async () => {
      if (!auth.currentUser) return;

      try {
        // Update the path to match your Firestore structure
        const profileDoc = await getDoc(doc(db, 'horseProfiles', auth.currentUser.uid));
        const profileData = profileDoc.data() as UserProfile;
        setUserProfile(profileData);
        setProfileChecked(true);
        
        // Debug log to check the data
        console.log('Loaded profile:', profileData);

        // If we have a profile with a horse name, personalize the suggestions
        if (profileData?.horseName && suggestions.length > 0) {
          const personalizedSugs = suggestions.map(suggestion => 
            suggestion.replace('meinem Pferd', profileData.horseName)
          );
          setPersonalizedSuggestions(personalizedSugs);
        }
      } catch (error) {
        console.error('Error fetching horse profile:', error);
        setProfileChecked(true);
      }
    };

    if (auth.currentUser) {
      checkUserProfile();
    }
  }, [auth.currentUser, suggestions]); // Added suggestions as dependency

  // Add this to debug render
  useEffect(() => {
    console.log('Current suggestions:', suggestions);
  }, [suggestions]);

  // Update the suggestion click handler
  const handleSuggestionClick = async (suggestion: string) => {
    if (!auth.currentUser) return;
    
    try {
      trackEvent('Chat', 'Suggestion Clicked', suggestion);

      // Create new session if none exists
      if (!currentSessionId) {
        await startNewChat();
        // Wait briefly for the new session to be created
        await new Promise(resolve => setTimeout(resolve, 100));
      }

      const sessionId = currentSessionId;
      if (!sessionId) {
        console.error('No active session');
        return;
      }

      // Create and save user message
      const userMessage: ChatMessage = {
        role: 'user',
        content: suggestion,
        timestamp: new Date(),
      };

      setMessages(prev => [...prev, userMessage]);
      setIsLoading(true);

      // Save user message to Firestore
      await addDoc(
        collection(db, `chatSessions/${sessionId}/messages`),
        userMessage
      );

      // Get AI response
      const responseData = await sendMessage(suggestion, auth.currentUser.uid, sessionId);
      
      const botMessage: ChatMessage = {
        role: 'assistant',
        content: responseData,
        timestamp: new Date(),
      };

      // Save bot message to Firestore
      await addDoc(
        collection(db, `chatSessions/${sessionId}/messages`),
        botMessage
      );

      // Update session metadata
      await updateDoc(doc(db, 'chatSessions', sessionId), {
        lastMessage: botMessage.content.substring(0, 100) + '...',
        updatedAt: serverTimestamp(),
        ...(messages.length === 0 && { title: suggestion.substring(0, 30) + '...' })
      });

      setMessages(prev => [...prev, botMessage]);
    } catch (error) {
      console.error('Error handling suggestion:', error);
      handleError(error, 'handleSuggestionClick');
    } finally {
      setIsLoading(false);
    }
  };

  // Update the isProfileComplete function to check if ANY field is missing
  const isProfileComplete = (profile: UserProfile | null): boolean => {
    if (!profile) return false;
    
    // Check if any required field is missing or empty
    const missingFields = getMissingFields(profile);
    return missingFields.person.length === 0 && missingFields.horse.length === 0;
  };

  // Update the effect that generates questions
  useEffect(() => {
    const generateQuestions = async () => {
      if (userProfile && Object.values(userProfile).some(val => val?.trim())) {
        setIsInitialLoading(true);
        try {
          const questions = await generatePersonalizedSuggestions(auth.currentUser!.uid);
          setPersonalizedSuggestions(questions);
        } catch (error) {
          console.error('Failed to generate personalized questions:', error);
        } finally {
          setIsInitialLoading(false);
        }
      }
    };

    generateQuestions();
  }, [userProfile, auth.currentUser]);

  // Add this function inside the Chat component
  const refreshSuggestions = async () => {
    if (auth.currentUser) {
      setIsRefreshing(true);
      try {
        const questions = await generatePersonalizedSuggestions(auth.currentUser.uid);
        setPersonalizedSuggestions(questions);
      } catch (error) {
        console.error('Failed to refresh personalized questions:', error);
      } finally {
        setIsRefreshing(false);
      }
    }
  };

  // Update the getMissingFields function to check each field individually
  const getMissingFields = (profile: UserProfile | null) => {
    const missing = {
      person: [] as string[],
      horse: [] as string[]
    };

    // If no profile, all fields are missing
    if (!profile) {
      missing.person = ['Name', 'Beschreibung'];
      missing.horse = ['Name', 'Rasse', 'Alter', 'Stockmaß', 'Beschreibung'];
      return missing;
    }

    // Check each field individually
    if (!profile.ownerName?.trim()) missing.person.push('Name');
    if (!profile.ownerInfo?.trim()) missing.person.push('Beschreibung');
    
    if (!profile.horseName?.trim()) missing.horse.push('Name');
    if (!profile.horseBreed?.trim()) missing.horse.push('Rasse');
    if (!profile.horseAge?.trim()) missing.horse.push('Alter');
    if (!profile.horseHeight?.trim()) missing.horse.push('Stockmaß');
    if (!profile.horseDescription?.trim()) missing.horse.push('Beschreibung');
    
    return missing;
  };

  // Add delete chat function
  const deleteChat = async (sessionId: string, event: React.MouseEvent) => {
    event.stopPropagation(); // Prevent chat selection when clicking delete
    if (!auth.currentUser) return;
    
    try {
      // Delete from Firestore
      await deleteDoc(doc(db, 'chatSessions', sessionId));
      
      // Update local state
      setChatSessions(prev => prev.filter(session => session.id !== sessionId));
      
      // If deleted current chat, clear messages and select another chat
      if (currentSessionId === sessionId) {
        setMessages([]);
        setCurrentSessionId(null);
      }
    } catch (error) {
      console.error('Error deleting chat:', error);
    }
  };

  // Add this to your existing useEffect that handles initial loading
  useEffect(() => {
    // Temporarily disabled weight check functionality
    /*
    const handleWeightCheckPrompt = async () => {
      const weightCheckPrompt = localStorage.getItem('weightCheckPrompt');
      if (weightCheckPrompt) {
        localStorage.removeItem('weightCheckPrompt');
        const newSessionId = await startNewChat();
        if (newSessionId) {
          await new Promise(resolve => setTimeout(resolve, 100));
          
          const userMessage: ChatMessage = {
            role: 'user',
            content: weightCheckPrompt,
            timestamp: new Date(),
          };

          setMessages(prev => [...prev, userMessage]);

          try {
            await addDoc(
              collection(db, `chatSessions/${newSessionId}/messages`),
              userMessage
            );

            const sessionRef = doc(db, 'chatSessions', newSessionId);
            await updateDoc(sessionRef, {
              title: 'Gewichtsberatung',
              lastMessage: weightCheckPrompt,
              updatedAt: serverTimestamp()
            });

            setIsLoading(true);
            const response = await sendMessage(weightCheckPrompt);
            
            if (response) {
              const aiMessage: ChatMessage = {
                role: 'assistant',
                content: response,
                timestamp: new Date(),
              };

              setMessages(prev => [...prev, aiMessage]);

              await addDoc(
                collection(db, `chatSessions/${newSessionId}/messages`),
                aiMessage
              );
            }
          } catch (error) {
            console.error('Error handling weight check prompt:', error);
          } finally {
            setIsLoading(false);
          }
        }
      }
    };

    if (auth.currentUser) {
      handleWeightCheckPrompt();
    }
    */
  }, [auth.currentUser]);

  const prepareSystemPrompt = async () => {
    const user = auth.currentUser;
    if (!user) return defaultSystemPrompt;

    try {
      // Get the horse profile
      const profileRef = doc(db, "horseProfiles", user.uid);
      const profileDoc = await getDoc(profileRef);
      
      if (profileDoc.exists()) {
        const horseProfile = profileDoc.data();
        const horseInfo = summarizeHorseProfile(horseProfile);
        
        // Create a system prompt that includes the horse information
        return `Ich bin ein Pferde-Experte und Berater. 
Hier sind die Details zum Pferd des Nutzers:

${horseInfo}

Bitte berücksichtige diese Informationen bei allen Antworten und Ratschlägen.
Passe deine Empfehlungen an das spezifische Pferd und seine Situation an.`;
      }
    } catch (error) {
      console.error("Error loading horse profile for chat:", error);
    }

    return defaultSystemPrompt;
  };

  // Add share chat function
  const shareChat = async (sessionId: string, event: React.MouseEvent) => {
    event.stopPropagation();
    event.preventDefault();
    if (!auth.currentUser) return;
    
    try {
      // Track share attempt
      trackEvent('Chat', 'Share Initiated', sessionId);

      console.log('Sharing chat:', sessionId);

      // Get the chat messages
      const messagesQuery = query(
        collection(db, `chatSessions/${sessionId}/messages`),
        orderBy('timestamp')
      );
      const messagesSnapshot = await getDocs(messagesQuery);
      const messages = messagesSnapshot.docs.map(doc => ({
        ...doc.data(),
        timestamp: doc.data().timestamp?.toDate() || new Date()
      }));

      // Get the chat session
      const sessionDoc = await getDoc(doc(db, 'chatSessions', sessionId));
      const session = sessionDoc.data();

      // Create a shared version in a separate collection
      const sharedChat = await addDoc(collection(db, 'sharedChats'), {
        originalSessionId: sessionId,
        title: session?.title || 'Shared Chat',
        messages,
        createdAt: serverTimestamp(),
        createdBy: auth.currentUser.uid,
        isPublic: true
      });

      // Update original session with share info
      await updateDoc(doc(db, 'chatSessions', sessionId), {
        isShared: true,
        shareId: sharedChat.id
      });

      // Show success message with share URL
      const shareUrl = `${window.location.origin}/shared/${sharedChat.id}`;
      setShareSuccess(shareUrl);
      
      // Copy to clipboard
      await navigator.clipboard.writeText(shareUrl);

      // Clear success message after 3 seconds
      setTimeout(() => setShareSuccess(null), 3000);

      // Track successful share
      trackEvent('Chat', 'Share Successful', sessionId);

    } catch (error) {
      console.error('Error sharing chat:', error);
      handleError(error, 'shareChat');
      setShareSuccess('Error sharing chat. Please try again.');
      setTimeout(() => setShareSuccess(null), 3000);
    }
  };

  // Add this function to cycle through loading texts
  useEffect(() => {
    let interval: NodeJS.Timeout;
    if (isLoading) {
      let texts = ['Ich denke nach', 'Ich formuliere', 'Ich recherchiere'];
      let index = 0;
      interval = setInterval(() => {
        index = (index + 1) % texts.length;
        setLoadingText(texts[index]);
      }, 2000); // Change text every 2 seconds
    }
    return () => clearInterval(interval);
  }, [isLoading]);

  // Track page view
  useEffect(() => {
    trackPageView('/chat');
  }, []);

  // Load and initialize credits when component mounts or user changes
  useEffect(() => {
    const loadCredits = async () => {
      if (auth.currentUser) {
        try {
          // Initialize credits for the user (will handle both new and existing users)
          await initializeUserCredits(auth.currentUser.uid);
          const credits = await getRemainingCredits(auth.currentUser.uid);
          setRemainingCredits(credits);
        } catch (error) {
          console.error('Error loading credits:', error);
        }
      }
    };

    loadCredits();
  }, [auth.currentUser]);

  // Add credit alert component
  const CreditAlert = () => (
    <div className="fixed inset-x-0 bottom-24 mx-auto max-w-md px-4 z-50">
      <div className="bg-yellow-50 border-l-4 border-yellow-400 p-4 rounded-lg shadow-lg">
        <div className="flex">
          <div className="flex-shrink-0">
            <svg className="h-5 w-5 text-yellow-400" viewBox="0 0 20 20" fill="currentColor">
              <path fillRule="evenodd" d="M8.257 3.099c.765-1.36 2.722-1.36 3.486 0l5.58 9.92c.75 1.334-.213 2.98-1.742 2.98H4.42c-1.53 0-2.493-1.646-1.743-2.98l5.58-9.92zM11 13a1 1 0 11-2 0 1 1 0 012 0zm-1-8a1 1 0 00-1 1v3a1 1 0 002 0V6a1 1 0 00-1-1z" clipRule="evenodd" />
            </svg>
          </div>
          <div className="ml-3">
            <p className="text-sm text-yellow-700">
              Sie haben Ihr tägliches Nachrichtenlimit erreicht. Neue Nachrichten sind morgen wieder verfügbar.
            </p>
          </div>
          <div className="ml-auto pl-3">
            <button
              onClick={() => setShowCreditAlert(false)}
              className="inline-flex text-yellow-400 hover:text-yellow-500"
            >
              <span className="sr-only">Schließen</span>
              <X size={20} />
            </button>
          </div>
        </div>
      </div>
    </div>
  );

  // Add welcome popup component
  const WelcomePopup = () => (
    <div className="fixed inset-x-0 top-20 mx-auto max-w-md px-4 z-50">
      <div className="bg-blue-50 border-l-4 border-blue-400 p-4 rounded-lg shadow-lg">
        <div className="flex">
          <div className="flex-shrink-0">
            <AlertTriangle className="h-5 w-5 text-blue-400" />
          </div>
          <div className="ml-3">
            <h3 className="text-sm font-medium text-blue-800">
              Willkommen zur Beta-Version!
            </h3>
            <div className="mt-2 text-sm text-blue-700">
              <p>
                Dies ist eine Beta-Version. Bitte seien Sie vorsichtig mit den Antworten 
                und beachten Sie, dass noch nicht alle Funktionen vollständig sind.
              </p>
              <p className="mt-1">
                Sie haben für heute noch {remainingCredits} Nachrichten zur Verfügung.
              </p>
              <p className="mt-2 text-xs text-blue-600">
                Falls Sie Feedback teilen möchten, gerne per Chat unten rechts oder per Mail an{' '}
                <a 
                  href="mailto:info@equiexpert.xyz" 
                  className="underline hover:text-blue-800"
                  onClick={(e) => {
                    e.stopPropagation();
                    trackEvent('Feedback', 'Email Link Clicked');
                  }}
                >
                  info@equiexpert.xyz
                </a>
              </p>
            </div>
          </div>
          <div className="ml-auto pl-3">
            <button
              onClick={() => setShowWelcomePopup(false)}
              className="inline-flex text-blue-400 hover:text-blue-500"
            >
              <span className="sr-only">Schließen</span>
              <X size={20} />
            </button>
          </div>
        </div>
      </div>
    </div>
  );

  // Add effect to load horse profile when user changes
  useEffect(() => {
    const loadProfile = async () => {
      if (auth.currentUser) {
        const profile = await loadHorseProfile(auth.currentUser.uid);
        setHorseProfile(profile);
      }
    };

    loadProfile();
  }, [auth.currentUser]);

  return (
    <div className="flex h-screen bg-white">
      {/* Show welcome popup */}
      {showWelcomePopup && <WelcomePopup />}

      {/* Toggle button - only visible on mobile/tablet */}
      <button
        onClick={() => setIsSidebarOpen(!isSidebarOpen)}
        className={`
          lg:hidden fixed 
          left-4 top-1/2 -translate-y-1/2 
          z-50 p-2.5 
          bg-white border border-gray-200
          text-gray-700 
          rounded-full 
          hover:bg-gray-50 
          transition-all duration-200
          shadow-md hover:shadow-lg
          ${isSidebarOpen ? 'translate-x-64' : 'translate-x-0'}
        `}
        aria-label={isSidebarOpen ? "Close sidebar" : "Open sidebar"}
      >
        {isSidebarOpen ? <X size={20} /> : <Menu size={20} />}
      </button>

      {/* Sidebar - always visible on large screens */}
      <div 
        className={`
          fixed lg:relative
          top-16 lg:top-0 
          left-0
          h-[calc(100vh-64px)] lg:h-screen
          ${isSidebarOpen ? 'w-64' : 'w-0 lg:w-64'}
          bg-gray-50 border-r border-gray-200 
          transition-all duration-300 
          overflow-hidden
          z-40
        `}
      >
        <div className="flex flex-col h-full">
          <div className="pt-4 md:pt-20">
            {/* Login button */}
            <div className="px-4 mb-4">
              {!auth.currentUser && (
                <button
                  onClick={() => setShowLoginForm(true)}
                  className="w-full flex items-center justify-center space-x-2 bg-gray-100 text-gray-700 px-4 py-2 rounded-lg hover:bg-gray-200 transition-colors"
                >
                  <span>Anmelden</span>
                </button>
              )}
            </div>

            {/* New Chat button container */}
            <div className="px-4 mb-4">
              <button
                onClick={startNewChat}
                className="w-full flex items-center justify-center space-x-2 bg-blue-600 text-white px-4 py-2 rounded-lg hover:bg-blue-700 transition-colors"
              >
                <Plus size={20} />
                <span>New Chat</span>
              </button>
            </div>

            {/* Chat sessions container with adjusted height */}
            <div className="overflow-y-auto px-4" style={{ height: 'calc(100vh - 120px)' }}>
              <div className="space-y-2">
                {chatSessions.map(session => (
                  <button
                    key={session.id}
                    onClick={() => {
                      loadChatHistory(session.id);
                      // Auto-close sidebar on mobile after selection
                      if (window.innerWidth < 768) {
                        setIsSidebarOpen(false);
                      }
                    }}
                    className={`w-full text-left p-2 md:p-3 rounded-lg transition-colors group ${
                      currentSessionId === session.id
                        ? 'bg-blue-100 text-blue-800'
                        : 'hover:bg-gray-100'
                    }`}
                  >
                    <div className="flex items-center space-x-2 md:space-x-3">
                      <MessageCircle size={16} className="md:w-[18px] md:h-[18px]" />
                      <div className="flex-1 truncate">
                        <p className="font-medium text-sm md:text-base">{session.title}</p>
                        <p className="text-xs md:text-sm text-gray-500 truncate">
                          {session.lastMessage || 'New conversation'}
                        </p>
                      </div>
                      <button
                        onClick={(e) => deleteChat(session.id, e)}
                        className={`opacity-0 group-hover:opacity-100 p-1 rounded-full hover:bg-gray-200 transition-all ${
                          currentSessionId === session.id ? 'text-blue-700' : 'text-gray-500'
                        }`}
                        aria-label="Delete chat"
                      >
                        <Trash2 size={14} />
                      </button>
                    </div>
                  </button>
                ))}
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* Main chat area - adjusts based on screen size */}
      <div 
        className="flex-1 flex flex-col h-screen pt-16"
        onClick={() => {
          // Only close sidebar on mobile/tablet when clicking main area
          if (window.innerWidth < 1024 && isSidebarOpen) {
            setIsSidebarOpen(false);
          }
        }}
      >
        {/* Success message toast */}
        {shareSuccess && (
          <div className="fixed bottom-24 left-1/2 transform -translate-x-1/2 bg-green-100 border border-green-400 text-green-700 px-6 py-3 rounded-full shadow-lg z-50 flex items-center space-x-3 animate-fade-in">
            <div className="flex items-center space-x-2">
              <svg className="w-5 h-5 text-green-500" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M5 13l4 4L19 7" />
              </svg>
              <span className="font-medium">Link von diesem Chat zum Teilen in Zwischenablage kopiert</span>
            </div>
            <button 
              onClick={() => setShareSuccess(null)}
              className="text-green-700 hover:text-green-800"
            >
              <X size={16} />
            </button>
          </div>
        )}

        {/* Profile completion notice */}
        {auth.currentUser && !isProfileComplete(userProfile) && !isNoticeHidden && messages.length === 0 && (
          <div className="bg-yellow-50 border-l-4 border-yellow-400 p-4">
            <div className="flex">
              <div className="flex-shrink-0">
                <svg className="h-5 w-5 text-yellow-400" viewBox="0 0 20 20" fill="currentColor">
                  <path fillRule="evenodd" d="M8.257 3.099c.765-1.36 2.722-1.36 3.486 0l5.58 9.92c.75 1.334-.213 2.98-1.742 2.98H4.42c-1.53 0-2.493-1.646-1.743-2.98l5.58-9.92zM11 13a1 1 0 11-2 0 1 1 0 012 0zm-1-8a1 1 0 00-1 1v3a1 1 0 002 0V6a1 1 0 00-1-1z" clipRule="evenodd" />
                </svg>
              </div>
              <div className="ml-3">
                <p className="text-sm text-yellow-700">
                  Vervollständige dein Profil mit deinen Daten und den Informationen deines Pferdes für bessere Vorschläge.
                </p>
                <p className="mt-2 text-sm">
                  <Link to="/profile" className="text-yellow-700 hover:text-yellow-600 font-medium">
                    Zum Profil →
                  </Link>
                </p>
              </div>
              <div className="ml-auto pl-3">
                <button
                  onClick={() => setIsNoticeHidden(true)}
                  className="inline-flex text-yellow-400 hover:text-yellow-500"
                >
                  <span className="sr-only">Dismiss</span>
                  <X size={20} />
                </button>
              </div>
            </div>
          </div>
        )}

        {/* Welcome message or chat history */}
        {messages.length === 0 ? (
          <div className="flex-1 flex items-center justify-center bg-gray-50 pt-4">
            <div className="max-w-2xl w-full px-4">
              {/* Welcome Message */}
              <div className="text-center mb-8">
                <h1 className="text-3xl font-bold text-gray-900 mb-4">
                  Ihr KI-Pferdeexperte für alle Fragen rund um Pferd 🐎
                </h1>
              </div>

              {/* Suggestions Section */}
              <div className="space-y-4">
                <div className="flex justify-between items-center">
                  <h2 className="text-lg font-medium text-gray-700">
                    {userProfile?.horseName 
                      ? `Personalisierte Vorschläge für ${userProfile.horseName}:`
                      : "Häufig gestellte Fragen:"}
                  </h2>
                </div>

                <div className="grid grid-cols-1 gap-3">
                  {suggestions.map((suggestion, index) => (
                    <button
                      key={index}
                      onClick={() => handleSuggestionClick(suggestion)}
                      className={suggestionButtonStyle}
                    >
                      {suggestion}
                    </button>
                  ))}
                </div>
              </div>
            </div>
          </div>
        ) : (
          <div className="flex-1 overflow-y-auto pt-4">
            {/* Message bubbles */}
            {messages.map((message, index) => (
              <div
                key={index}
                className={`py-4 px-3 md:py-6 md:px-8 ${
                  message.role === 'assistant' ? 'bg-gray-50' : 'bg-white'
                }`}
              >
                <div className="max-w-3xl mx-auto flex space-x-3 md:space-x-4">
                  <div className={`w-8 h-8 rounded-full flex items-center justify-center flex-shrink-0 ${
                    message.role === 'assistant' ? 'bg-blue-600 text-white' : 'bg-gray-200 text-gray-600'
                  }`}>
                    {message.role === 'assistant' ? 'E' : 'S'}
                  </div>
                  <div className="min-w-0 flex-1 break-words overflow-hidden relative">
                    {message.role === 'assistant' ? (
                      <>
                        <ReactMarkdown
                          className="prose prose-blue max-w-none prose-pre:whitespace-pre-wrap prose-pre:break-words"
                          components={{
                            // ... existing markdown components ...
                          }}
                        >
                          {message.content}
                        </ReactMarkdown>
                        
                        {/* Add suggested questions display */}
                        {message.suggestedQuestions && message.suggestedQuestions.length > 0 && (
                          <div className="mt-4 space-y-2">
                            <p className="text-sm font-medium text-gray-700">
                              Weiterführende Fragen:
                            </p>
                            <div className="flex flex-col gap-2">
                              {message.suggestedQuestions.map((question, qIndex) => (
                                <button
                                  key={qIndex}
                                  onClick={() => handleSuggestionClick(question)}
                                  className="text-left text-sm text-blue-600 hover:text-blue-800 
                                           hover:bg-blue-50 rounded-lg p-2 transition-colors"
                                >
                                  {question}
                                </button>
                              ))}
                            </div>
                          </div>
                        )}
                      </>
                    ) : (
                      <p className="text-gray-700 break-words whitespace-pre-wrap">
                        {message.content}
                      </p>
                    )}
                  </div>
                </div>
              </div>
            ))}
            {/* Loading indicator */}
            {isLoading && (
              <div className="py-6 px-4 md:px-8 bg-gray-50">
                <div className="max-w-3xl mx-auto flex space-x-4">
                  <div className="w-8 h-8 rounded-full bg-blue-600 flex items-center justify-center flex-shrink-0 text-white">
                    E
                  </div>
                  <div className="flex items-center">
                    <div className="flex flex-col">
                      <div className="text-gray-600 text-sm mb-1">{loadingText}</div>
                      <div className="loading-dots">
                        <span className="dot"></span>
                        <span className="dot"></span>
                        <span className="dot"></span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            )}
            <div ref={messagesEndRef} />
          </div>
        )}

        {/* Message input form with credits display */}
        <div className="border-t border-gray-200 bg-white p-3 md:p-4">
          <form onSubmit={handleSend} className="max-w-3xl mx-auto space-y-4">
            {/* Credits display */}
            {remainingCredits !== null && (
              <div className="flex justify-end">
                <span className="text-sm text-gray-500">
                  Verbleibende Nachrichten heute: {remainingCredits}
                </span>
              </div>
            )}

            {/* Input field */}
            <input
              type="text"
              value={input}
              onChange={(e) => setInput(e.target.value)}
              onKeyDown={(e) => {
                if (e.key === 'Enter' && !e.shiftKey) {
                  e.preventDefault();
                  if (input.trim() && !isLoading) {
                    handleSend(e);
                  }
                }
              }}
              placeholder="Stellen Sie Ihre Frage zu allem rund um Pferd und Mensch..."
              className="w-full p-3 md:p-4 rounded-xl border border-gray-300 
                       focus:border-blue-500 focus:ring-2 focus:ring-blue-200 
                       focus:outline-none text-gray-700 placeholder-gray-500 
                       text-sm md:text-base"
              disabled={isLoading}
            />

            {/* Button container */}
            <div className="flex justify-center space-x-4">
              {/* Share button - only show if we have a current session */}
              {currentSessionId && messages.length > 0 && (
                <button
                  onClick={(e) => {
                    e.preventDefault();
                    shareChat(currentSessionId, e);
                  }}
                  className="px-6 py-2.5 text-gray-600 hover:text-blue-600 
                           bg-gray-100 hover:bg-gray-200 rounded-xl 
                           transition-colors flex items-center space-x-2"
                  title="Chat teilen"
                >
                  <Share2 size={20} />
                  <span>Teilen</span>
                </button>
              )}
              {/* Send button */}
              <button
                type="submit"
                disabled={isLoading || !input.trim()}
                className="px-6 py-2.5 text-white bg-[#4263EB] hover:bg-blue-600 
                         rounded-xl transition-colors flex items-center space-x-2
                         disabled:opacity-50 disabled:hover:bg-[#4263EB]"
                title="Nachricht senden"
              >
                <Send size={20} />
                <span>Senden</span>
              </button>
            </div>

            {/* Warning message */}
            <p className="text-xs text-gray-500 text-center px-2 break-words">
              ⚠️ EquiExpert kann Fehler machen. Bei medizinischen Fragen und wichtigen Entscheidungen konsultieren Sie bitte einen Tierarzt oder qualifizierten Pferdeexperten.
            </p>
          </form>
        </div>

        {/* Show credit alert when needed */}
        {showCreditAlert && <CreditAlert />}

        <style>{`
          .animate-fade-in {
            animation: fadeIn 0.3s ease-in-out;
          }
          
          @keyframes fadeIn {
            from {
              opacity: 0;
              transform: translate(-50%, 1rem);
            }
            to {
              opacity: 1;
              transform: translate(-50%, 0);
            }
          }
          
          .loading-dots {
            display: flex;
            gap: 4px;
          }
          .dot {
            width: 8px;
            height: 8px;
            background-color: #2563eb;
            border-radius: 50%;
            animation: bounce 1.4s infinite ease-in-out both;
          }
          .dot:nth-child(1) { animation-delay: -0.32s; }
          .dot:nth-child(2) { animation-delay: -0.16s; }
          @keyframes bounce {
            0%, 80%, 100% { 
              transform: scale(0);
            } 
            40% { 
              transform: scale(1.0);
            }
          }
        `}</style>
      </div>

      {/* Add LoginForm modal */}
      {showLoginForm && (
        <LoginForm onClose={() => setShowLoginForm(false)} />
      )}
    </div>
  );
};

export default Chat; 