import React, { useState, useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { collection, addDoc, serverTimestamp } from 'firebase/firestore';
import { auth, db } from '../../firebase';
import { QuizQuestion, getQuizQuestions } from '../../services/quizService';

interface QuizState {
  category: string;
  difficulty: string;
}

const Quiz: React.FC = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const state = location.state as QuizState;

  const [currentQuestionIndex, setCurrentQuestionIndex] = useState(0);
  const [selectedAnswer, setSelectedAnswer] = useState<string>('');
  const [showResult, setShowResult] = useState(false);
  const [score, setScore] = useState(0);
  const [questions, setQuestions] = useState<QuizQuestion[]>([]);
  const [answers, setAnswers] = useState<string[]>([]);
  const [loading, setLoading] = useState(true);
  const [showExplanation, setShowExplanation] = useState(false);
  const [error, setError] = useState<string | null>(null);

  useEffect(() => {
    const loadQuestions = async () => {
      if (!state?.category || !state?.difficulty) {
        navigate('/quiz');
        return;
      }

      try {
        setLoading(true);
        setError(null);
        
        // Get questions from Firebase
        const allQuestions = await getQuizQuestions(state.category, state.difficulty);
        
        if (allQuestions.length === 0) {
          setError('Keine Fragen für diese Kategorie und Schwierigkeitsgrad gefunden.');
          return;
        }

        // Randomly select 5 questions
        const selectedQuestions = allQuestions
          .sort(() => Math.random() - 0.5)
          .slice(0, 5);
        
        setQuestions(selectedQuestions);
      } catch (error) {
        console.error('Error loading questions:', error);
        setError('Fehler beim Laden der Fragen. Bitte versuche es später erneut.');
      } finally {
        setLoading(false);
      }
    };

    loadQuestions();
  }, [state, navigate]);

  const handleAnswerSelect = (answer: string) => {
    setSelectedAnswer(answer);
  };

  const handleNext = async () => {
    if (selectedAnswer === '') return;

    const newAnswers = [...answers, selectedAnswer];
    setAnswers(newAnswers);

    if (selectedAnswer === questions[currentQuestionIndex].correctAnswer) {
      setScore(score + 1);
    }

    setShowExplanation(true);

    // Show explanation for 3 seconds before moving to next question
    setTimeout(() => {
      setShowExplanation(false);
      setSelectedAnswer('');

      if (currentQuestionIndex < questions.length - 1) {
        setCurrentQuestionIndex(currentQuestionIndex + 1);
      } else {
        setShowResult(true);
        saveQuizResult(newAnswers);
      }
    }, 3000);
  };

  const saveQuizResult = async (finalAnswers: string[]) => {
    if (!auth.currentUser) return;

    try {
      await addDoc(collection(db, 'quizResults'), {
        userId: auth.currentUser.uid,
        category: state.category,
        difficulty: state.difficulty,
        score,
        totalQuestions: questions.length,
        answers: finalAnswers,
        timestamp: serverTimestamp(),
      });
    } catch (error) {
      console.error('Error saving quiz result:', error);
    }
  };

  const handleRetry = () => {
    navigate('/quiz');
  };

  if (loading) {
    return (
      <div className="min-h-screen bg-gray-50 p-6 pt-20 flex items-center justify-center">
        <div className="text-center">
          <div className="animate-spin rounded-full h-12 w-12 border-b-2 border-blue-500 mx-auto"></div>
          <p className="mt-4 text-gray-600">Lade Quiz...</p>
        </div>
      </div>
    );
  }

  if (error) {
    return (
      <div className="min-h-screen bg-gray-50 p-6 pt-20">
        <div className="max-w-2xl mx-auto">
          <div className="bg-white rounded-2xl shadow-sm border border-gray-100 overflow-hidden p-6">
            <div className="text-center">
              <p className="text-red-600 mb-4">{error}</p>
              <button
                onClick={handleRetry}
                className="bg-blue-600 text-white px-6 py-2 rounded-lg hover:bg-blue-700 transition-colors"
              >
                Zurück zur Kategorieauswahl
              </button>
            </div>
          </div>
        </div>
      </div>
    );
  }

  if (showResult) {
    return (
      <div className="min-h-screen bg-gray-50 p-6 pt-20">
        <div className="max-w-2xl mx-auto">
          <div className="bg-white rounded-2xl shadow-sm border border-gray-100 overflow-hidden p-6">
            <h2 className="text-3xl font-bold text-center mb-6">Quiz Ergebnis</h2>
            <div className="text-center mb-8">
              <p className="text-6xl font-bold text-blue-600 mb-2">{score}/{questions.length}</p>
              <p className="text-gray-600">Richtige Antworten</p>
            </div>
            
            <div className="space-y-6">
              {questions.map((q, index) => (
                <div key={q.id} className="border rounded-lg p-4">
                  <p className="font-medium mb-2">{q.question}</p>
                  <p className="text-sm">
                    Deine Antwort: 
                    <span className={answers[index] === q.correctAnswer ? 'text-green-600' : 'text-red-600'}>
                      {' '}{answers[index]}
                    </span>
                  </p>
                  {answers[index] !== q.correctAnswer && (
                    <p className="text-sm text-green-600">
                      Richtige Antwort: {q.correctAnswer}
                    </p>
                  )}
                  <p className="text-sm text-gray-600 mt-2">{q.explanation}</p>
                </div>
              ))}
            </div>

            <div className="mt-8 flex justify-center">
              <button
                onClick={handleRetry}
                className="bg-blue-600 text-white px-6 py-2 rounded-lg hover:bg-blue-700 transition-colors"
              >
                Neues Quiz starten
              </button>
            </div>
          </div>
        </div>
      </div>
    );
  }

  if (questions.length === 0) {
    return (
      <div className="min-h-screen bg-gray-50 p-6 pt-20">
        <div className="max-w-2xl mx-auto">
          <div className="bg-white rounded-2xl shadow-sm border border-gray-100 overflow-hidden p-6">
            <div className="text-center">
              <p className="text-gray-600 mb-4">Keine Fragen verfügbar.</p>
              <button
                onClick={handleRetry}
                className="bg-blue-600 text-white px-6 py-2 rounded-lg hover:bg-blue-700 transition-colors"
              >
                Zurück zur Kategorieauswahl
              </button>
            </div>
          </div>
        </div>
      </div>
    );
  }

  const currentQuestion = questions[currentQuestionIndex];

  return (
    <div className="min-h-screen bg-gray-50 p-6 pt-20">
      <div className="max-w-2xl mx-auto">
        <div className="bg-white rounded-2xl shadow-sm border border-gray-100 overflow-hidden">
          <div className="p-6">
            <div className="flex justify-between items-center mb-6">
              <p className="text-sm text-gray-600">
                Frage {currentQuestionIndex + 1} von {questions.length}
              </p>
              <p className="text-sm text-gray-600">
                Punkte: {score}
              </p>
            </div>

            <h3 className="text-xl font-semibold text-gray-900 mb-6">
              {currentQuestion.question}
            </h3>

            <div className="space-y-3">
              {currentQuestion.options.map((option) => (
                <button
                  key={option}
                  onClick={() => handleAnswerSelect(option)}
                  disabled={showExplanation}
                  className={`w-full p-4 rounded-lg border-2 text-left transition-all duration-200
                    ${showExplanation 
                      ? option === currentQuestion.correctAnswer
                        ? 'border-green-500 bg-green-50'
                        : option === selectedAnswer
                          ? 'border-red-500 bg-red-50'
                          : 'border-gray-200'
                      : selectedAnswer === option
                        ? 'border-blue-500 bg-blue-50'
                        : 'border-gray-200 hover:border-blue-200 hover:bg-gray-50'
                    }`}
                >
                  {option}
                </button>
              ))}
            </div>

            {showExplanation && (
              <div className="mt-4 p-4 bg-blue-50 rounded-lg border border-blue-100">
                <p className="text-blue-800">{currentQuestion.explanation}</p>
              </div>
            )}

            <button
              onClick={handleNext}
              disabled={!selectedAnswer || showExplanation}
              className={`w-full mt-6 py-3 px-4 rounded-lg font-medium transition-colors duration-200
                ${selectedAnswer && !showExplanation
                  ? 'bg-green-600 text-white hover:bg-green-700'
                  : 'bg-gray-300 text-gray-500 cursor-not-allowed'}`}
            >
              {currentQuestionIndex < questions.length - 1 ? 'Nächste Frage' : 'Quiz beenden'}
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Quiz; 