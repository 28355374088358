import React, { useState, useEffect } from 'react';
import { useNavigate, Link } from 'react-router-dom';
import { doc, getDoc, updateDoc } from 'firebase/firestore';
import { auth, db } from '../firebase';
import { summarizeHorseProfile } from './Profile';

// Interface for form data
interface HorseData {
  name: string;
  weight: string;
  breed: string;
  age: string;
  height: string;
  description: string;
}

// Interface for validation errors
interface FormErrors {
  weight?: string;
  breed?: string;
  age?: string;
  height?: string;
}

// Add interface for profile data near the top
interface ProfileData {
  ownerName?: string;
  ownerEmail?: string;
  horseName?: string;
  horseBreed?: string;
  horseWeight?: string;
  horseAge?: string;
  horseHeight?: string;
  horseDescription?: string;
}

const HorseWeightCheck: React.FC = () => {
  const navigate = useNavigate();
  
  // State for form data
  const [formData, setFormData] = useState<HorseData>({
    name: '',
    weight: '',
    breed: '',
    age: '',
    height: '',
    description: '',
  });
  
  // State for form errors
  const [errors, setErrors] = useState<FormErrors>({});
  // State for the result message
  const [result, setResult] = useState<string>('');

  // Reference weights for different horse breeds (in kg)
  const breedWeightRanges: { [key: string]: { min: number; max: number } } = {
    'Vollblut': { min: 450, max: 550 },
    'Warmblut': { min: 500, max: 600 },
    'Pony': { min: 200, max: 400 },
    'Kaltblut': { min: 700, max: 1000 },
    'Araber': { min: 400, max: 500 },
  };

  // Add effect to load profile data
  useEffect(() => {
    const loadProfileData = async () => {
      const user = auth.currentUser;
      if (!user) return;

      try {
        const profileRef = doc(db, "horseProfiles", user.uid);
        const profileDoc = await getDoc(profileRef);
        
        if (profileDoc.exists()) {
          const profileData = profileDoc.data() as ProfileData;
          
          // Set form data
          setFormData(prev => ({
            ...prev,
            name: profileData.horseName || '',
            weight: profileData.horseWeight || '',
            breed: profileData.horseBreed || '',
            age: profileData.horseAge || '',
            height: profileData.horseHeight || '',
            description: profileData.horseDescription || '',
          }));
        }
      } catch (error) {
        console.error("Error loading profile data:", error);
      }
    };

    loadProfileData();
  }, []);

  // Validate form inputs
  const validateForm = (): boolean => {
    const newErrors: FormErrors = {};
    
    if (!formData.weight) {
      newErrors.weight = 'Gewicht ist erforderlich';
    } else if (isNaN(Number(formData.weight)) || Number(formData.weight) <= 0) {
      newErrors.weight = 'Bitte geben Sie ein gültiges Gewicht ein';
    }
    
    if (!formData.breed) {
      newErrors.breed = 'Rasse ist erforderlich';
    }
    
    if (!formData.age) {
      newErrors.age = 'Alter ist erforderlich';
    } else if (isNaN(Number(formData.age)) || Number(formData.age) <= 0 || Number(formData.age) > 40) {
      newErrors.age = 'Bitte geben Sie ein gültiges Alter ein (1-40 Jahre)';
    }
    
    if (formData.height) {
      const heightNum = Number(formData.height);
      if (isNaN(heightNum) || heightNum < 100 || heightNum > 200) {
        newErrors.height = 'Bitte geben Sie ein gültiges Stockmaß zwischen 100 und 200 cm ein';
      }
    }
    
    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };

  // Calculate weight status and get chat prompt
  const calculateWeightStatus = () => {
    const weight = Number(formData.weight);
    const breedRange = breedWeightRanges[formData.breed];
    
    if (!breedRange) {
      return {
        message: 'Für diese Rasse liegen keine Referenzwerte vor',
        prompt: null
      };
    }
    
    const { min, max } = breedRange;
    const horseName = formData.name ? `${formData.name}` : 'Mein Pferd';
    
    // Calculate 10% ranges for slight over/underweight
    const slightlyUnderMin = min - (min * 0.1);
    const slightlyOverMax = max + (max * 0.1);
    
    // Get additional profile information
    const heightInfo = formData.height ? `mit einem Stockmaß von ${formData.height}cm` : '';
    
    if (weight < slightlyUnderMin) {
      return {
        message: `${horseName} liegt deutlich unter dem Normalgewicht für ${formData.breed}. Eine tierärztliche Beratung wäre sinnvoll.`,
        prompt: `Mein ${formData.breed} ${horseName} ist ${formData.age} Jahre alt ${heightInfo} und wiegt ${formData.weight}kg, was deutlich unter dem Normalgewicht (${min}kg - ${max}kg) liegt. Kannst du mir bitte dringende Ratschläge geben, wie ich das Gewicht gesund steigern kann? Welche Fütterungsanpassungen und sonstigen Maßnahmen empfiehlst du?`
      };
    } else if (weight >= slightlyUnderMin && weight < min) {
      return {
        message: `${horseName} liegt leicht unter dem Normalgewicht für ${formData.breed}. Eine Anpassung der Fütterung wäre sinnvoll.`,
        prompt: `Mein ${formData.breed} ${horseName} ist ${formData.age} Jahre alt ${heightInfo} und wiegt ${formData.weight}kg, was leicht unter dem Normalgewicht (${min}kg - ${max}kg) liegt. Welche moderaten Anpassungen der Fütterung empfiehlst du?`
      };
    } else if (weight > max && weight <= slightlyOverMax) {
      return {
        message: `${horseName} liegt leicht über dem Normalgewicht für ${formData.breed}. Eine kleine Anpassung wäre sinnvoll.`,
        prompt: `Mein ${formData.breed} ${horseName} ist ${formData.age} Jahre alt ${heightInfo} und wiegt ${formData.weight}kg, was leicht über dem Normalgewicht (${min}kg - ${max}kg) liegt. Welche moderaten Anpassungen empfiehlst du?`
      };
    } else if (weight > slightlyOverMax) {
      return {
        message: `${horseName} liegt deutlich über dem Normalgewicht für ${formData.breed}. Eine tierärztliche Beratung wäre sinnvoll.`,
        prompt: `Mein ${formData.breed} ${horseName} ist ${formData.age} Jahre alt ${heightInfo} und wiegt ${formData.weight}kg, was deutlich über dem Normalgewicht (${min}kg - ${max}kg) liegt. Kannst du mir bitte dringende Ratschläge geben, wie ich das Gewicht gesund reduzieren kann?`
      };
    } else {
      return {
        message: `${horseName} liegt im normalen Gewichtsbereich für ${formData.breed}.`,
        prompt: null
      };
    }
  };

  // Handle form submission
  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    if (validateForm()) {
      const status = calculateWeightStatus();
      setResult(status.message);
      
      // Store the prompt in localStorage if weight is not normal
      if (status.prompt) {
        localStorage.setItem('weightCheckPrompt', status.prompt);
      } else {
        localStorage.removeItem('weightCheckPrompt');
      }

      // Update profile if user is logged in
      const user = auth.currentUser;
      if (user) {
        try {
          const profileRef = doc(db, "horseProfiles", user.uid);
          await updateDoc(profileRef, {
            horseWeight: formData.weight,
            horseBreed: formData.breed,
            horseAge: formData.age,
            updatedAt: new Date().toISOString(),
          });
        } catch (error) {
          console.error("Error updating profile:", error);
        }
      }
    }
  };

  // Handle chat navigation
  const handleChatNavigation = () => {
    navigate('/chat', { state: { newChat: true } });
  };

  // Handle input changes
  const handleChange = (e: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>) => {
    const { name, value } = e.target;
    const newFormData = {
      ...formData,
      [name]: value
    };
    setFormData(newFormData);
  };

  // Add getIndividualSuggestion function
  const getIndividualSuggestion = () => {
    if (formData.breed && !formData.weight) {
      const range = breedWeightRanges[formData.breed];
      return `Für ${formData.breed} liegt das typische Gewicht zwischen ${range.min} und ${range.max} kg.`;
    }
    if (formData.height && !formData.breed) {
      return `Bei einem Stockmaß von ${formData.height}cm könnte es sich um ein Warmblut oder Vollblut handeln.`;
    }
    if (formData.weight && !formData.breed) {
      let suggestion = 'Basierend auf dem Gewicht könnte es sich um ';
      if (Number(formData.weight) < 400) suggestion += 'ein Pony oder einen Araber';
      else if (Number(formData.weight) > 700) suggestion += 'ein Kaltblut';
      else suggestion += 'ein Warmblut oder Vollblut';
      return suggestion + ' handeln.';
    }
    return null;
  };

  // Add function to get breed-specific information
  const getBreedInfo = () => {
    const messages = [];
    
    // Add breed information
    if (formData.breed) {
      const breedInfo: { [key: string]: string } = {
        'Vollblut': 'Vollblüter sind elegant, athletisch und für ihre Schnelligkeit bekannt.',
        'Warmblut': 'Warmblüter sind vielseitige Pferde, die sich besonders für Dressur und Springen eignen.',
        'Pony': 'Ponys sind robust und charakterstark, ideal für Kinder und leichte Erwachsene.',
        'Kaltblut': 'Kaltblüter sind kräftige Arbeitspferde mit ruhigem Temperament.',
        'Araber': 'Araber sind ausdauernd, intelligent und für ihre Eleganz bekannt.'
      };
      messages.push(breedInfo[formData.breed]);
    }

    // Add age-specific information
    if (formData.age) {
      const age = Number(formData.age);
      if (age < 3) {
        messages.push('Ihr junges Pferd befindet sich noch in der Entwicklung. Regelmäßige Gewichtskontrollen sind wichtig.');
      } else if (age > 20) {
        messages.push('Bei älteren Pferden ist besondere Aufmerksamkeit bei der Ernährung und Gewichtskontrolle erforderlich.');
      }
    }

    // Add height-specific information
    if (formData.height) {
      const height = Number(formData.height);
      if (height < 148) {
        messages.push('Mit diesem Stockmaß fällt Ihr Pferd in die Pony-Kategorie.');
      } else if (height > 170) {
        messages.push('Ihr Pferd ist überdurchschnittlich groß für die meisten Rassen.');
      }
    }

    return messages.length > 0 ? messages : null;
  };

  return (
    <div className="min-h-screen bg-gray-50 p-6 pt-20">
      <div className="max-w-2xl mx-auto">
        <h2 className="text-3xl font-bold text-gray-900 mb-6 text-center">Pferdegewicht-Check</h2>
        
        <div className="bg-white rounded-2xl shadow-sm border border-gray-100 overflow-hidden">
          <div className="p-6">
            <div className="flex justify-between items-center mb-6">
              <h3 className="text-xl font-semibold text-gray-900">Gewichtsberechnung</h3>
            </div>
            
            <form onSubmit={handleSubmit} className="space-y-4">
              <div>
                <label className="block text-sm font-medium text-gray-700 mb-2">Name (optional)</label>
                <input
                  type="text"
                  name="name"
                  value={formData.name}
                  onChange={handleChange}
                  className="w-full px-4 py-3 border border-gray-200 rounded-lg focus:ring-2 focus:ring-blue-500 focus:border-transparent transition-shadow duration-200"
                  placeholder="Name des Pferdes"
                />
              </div>

              <div>
                <label className="block text-sm font-medium text-gray-700 mb-2">
                  Stockmaß (cm) <span className="text-gray-400">(optional)</span>
                </label>
                <div className="relative">
                  <input
                    type="number"
                    name="height"
                    value={formData.height}
                    onChange={handleChange}
                    className="w-full px-4 py-3 border border-gray-200 rounded-lg focus:ring-2 focus:ring-blue-500 focus:border-transparent transition-shadow duration-200"
                    placeholder="170"
                    min="100"
                    max="200"
                    step="1"
                  />
                  <span className="absolute right-3 top-1/2 transform -translate-y-1/2 text-gray-500">cm</span>
                </div>
                {errors.height && <p className="text-red-500 text-sm mt-1">{errors.height}</p>}
              </div>

              <div>
                <label className="block text-sm font-medium text-gray-700 mb-2">
                  Gewicht (kg) <span className="text-red-500">*</span>
                </label>
                <input
                  type="number"
                  name="weight"
                  value={formData.weight}
                  onChange={handleChange}
                  className="w-full px-4 py-3 border border-gray-200 rounded-lg focus:ring-2 focus:ring-blue-500 focus:border-transparent transition-shadow duration-200"
                  placeholder="z.B. 500"
                  required
                />
                {errors.weight && <p className="text-red-500 text-sm mt-1">{errors.weight}</p>}
              </div>

              <div>
                <label className="block text-sm font-medium text-gray-700 mb-2">Rasse*</label>
                <select
                  name="breed"
                  value={formData.breed}
                  onChange={handleChange}
                  className="w-full px-4 py-3 border border-gray-200 rounded-lg focus:ring-2 focus:ring-blue-500 focus:border-transparent transition-shadow duration-200"
                  required
                >
                  <option value="">Rasse auswählen</option>
                  <option value="Vollblut">Vollblut</option>
                  <option value="Warmblut">Warmblut</option>
                  <option value="Pony">Pony</option>
                  <option value="Kaltblut">Kaltblut</option>
                  <option value="Araber">Araber</option>
                </select>
                {errors.breed && <p className="text-red-500 text-sm mt-1">{errors.breed}</p>}
              </div>

              <div>
                <label className="block text-sm font-medium text-gray-700 mb-2">Alter*</label>
                <input
                  type="number"
                  name="age"
                  value={formData.age}
                  onChange={handleChange}
                  className="w-full px-4 py-3 border border-gray-200 rounded-lg focus:ring-2 focus:ring-blue-500 focus:border-transparent transition-shadow duration-200"
                  placeholder="Alter in Jahren"
                  required
                />
                {errors.age && <p className="text-red-500 text-sm mt-1">{errors.age}</p>}
              </div>

              <div className="pt-4 border-t border-gray-200">
                <button
                  type="submit"
                  className="w-full bg-blue-600 text-white py-3 px-4 rounded-lg hover:bg-blue-700 transition-colors font-medium"
                >
                  Gewicht überprüfen
                </button>
              </div>
            </form>

            {/* Add breed info section before weight result */}
            {getBreedInfo() && (
              <div className="mt-6 p-4 bg-blue-50 rounded-lg border border-blue-100">
                {getBreedInfo()?.map((info, index) => (
                  <p key={index} className="text-blue-800 mb-2 last:mb-0">
                    {info}
                  </p>
                ))}
              </div>
            )}

            {result && (
              <div className="mt-6 p-4 bg-gray-50 rounded-lg border border-gray-200">
                <p className="text-center text-gray-900 mb-4">{result}</p>
                {localStorage.getItem('weightCheckPrompt') && (
                  <button
                    onClick={handleChatNavigation}
                    className="w-full mt-2 bg-green-600 text-white py-3 px-4 rounded-lg hover:bg-green-700 transition-colors font-medium"
                  >
                    Beratung im Chat erhalten
                  </button>
                )}
              </div>
            )}
          </div>
        </div>

        <p className="mt-6 text-sm text-gray-600 text-center">
          Hinweis: Diese Berechnung dient nur zur Orientierung. Bei gesundheitlichen Bedenken konsultieren Sie bitte einen Tierarzt.
        </p>
      </div>
    </div>
  );
};

export default HorseWeightCheck; 