import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { db } from '../firebase';
import { doc, getDoc } from 'firebase/firestore';
import ReactMarkdown from 'react-markdown';
import { ArrowLeft } from 'lucide-react';
import { Link } from 'react-router-dom';

interface SharedChatMessage {
  role: 'user' | 'assistant';
  content: string;
  timestamp: Date;
}

interface SharedChatData {
  title: string;
  messages: SharedChatMessage[];
  createdAt: Date;
}

const SharedChat: React.FC = () => {
  const { shareId } = useParams<{ shareId: string }>();
  const [chatData, setChatData] = useState<SharedChatData | null>(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState<string | null>(null);

  useEffect(() => {
    const loadSharedChat = async () => {
      if (!shareId) {
        setError('No share ID provided');
        setLoading(false);
        return;
      }

      try {
        const chatDoc = await getDoc(doc(db, 'sharedChats', shareId));
        
        if (!chatDoc.exists()) {
          setError('Chat not found');
          setLoading(false);
          return;
        }

        const data = chatDoc.data() as SharedChatData;
        setChatData(data);
      } catch (error) {
        console.error('Error loading shared chat:', error);
        setError('Failed to load chat');
      } finally {
        setLoading(false);
      }
    };

    loadSharedChat();
  }, [shareId]);

  if (loading) {
    return (
      <div className="min-h-screen bg-gray-50 flex items-center justify-center">
        <div className="loading-dots">
          <span className="dot"></span>
          <span className="dot"></span>
          <span className="dot"></span>
        </div>
      </div>
    );
  }

  if (error || !chatData) {
    return (
      <div className="min-h-screen bg-gray-50 flex flex-col items-center justify-center p-4">
        <div className="text-center">
          <h1 className="text-2xl font-bold text-gray-900 mb-4">
            {error || 'Something went wrong'}
          </h1>
          <Link
            to="/"
            className="inline-flex items-center text-blue-600 hover:text-blue-700"
          >
            <ArrowLeft size={20} className="mr-2" />
            Back to Home
          </Link>
        </div>
      </div>
    );
  }

  return (
    <div className="min-h-screen bg-gray-50">
      {/* Header */}
      <div className="bg-white border-b border-gray-200 fixed top-0 left-0 right-0 z-10">
        <div className="max-w-3xl mx-auto px-4 py-4">
          <div className="flex items-center justify-between">
            <Link
              to="/"
              className="inline-flex items-center text-gray-600 hover:text-gray-900"
            >
              <ArrowLeft size={20} className="mr-2" />
              Back to Home
            </Link>
            <h1 className="text-lg font-semibold text-gray-900">
              {chatData.title}
            </h1>
            <div className="w-8" /> {/* Spacer for alignment */}
          </div>
        </div>
      </div>

      {/* Chat messages */}
      <div className="max-w-3xl mx-auto pt-20 pb-8">
        {chatData.messages.map((message, index) => (
          <div
            key={index}
            className={`py-4 px-4 ${
              message.role === 'assistant' ? 'bg-gray-50' : 'bg-white'
            }`}
          >
            <div className="flex space-x-3 md:space-x-4">
              <div
                className={`w-8 h-8 rounded-full flex items-center justify-center flex-shrink-0 ${
                  message.role === 'assistant'
                    ? 'bg-blue-600 text-white'
                    : 'bg-gray-200 text-gray-600'
                }`}
              >
                {message.role === 'assistant' ? 'E' : 'S'}
              </div>
              <div className="flex-1 overflow-hidden">
                {message.role === 'assistant' ? (
                  <ReactMarkdown
                    className="prose prose-blue max-w-none prose-pre:whitespace-pre-wrap prose-pre:break-words"
                    components={{
                      h1: ({node, ...props}) => <h1 className="text-xl md:text-2xl font-bold mb-4 mt-6 text-gray-900 break-words" {...props} />,
                      h2: ({node, ...props}) => <h2 className="text-lg md:text-xl font-bold mb-3 mt-5 text-gray-900 break-words" {...props} />,
                      h3: ({node, ...props}) => <h3 className="text-base md:text-lg font-bold mb-2 mt-4 text-gray-900 break-words" {...props} />,
                      p: ({node, ...props}) => <p className="mb-3 leading-relaxed text-gray-700 break-words whitespace-pre-wrap" {...props} />,
                      ul: ({node, ...props}) => <ul className="list-disc ml-4 mb-3 space-y-1 text-gray-700 break-words" {...props} />,
                      ol: ({node, ...props}) => <ol className="list-decimal ml-4 mb-3 space-y-1 text-gray-700 break-words" {...props} />,
                      li: ({node, ...props}) => <li className="mb-1 text-gray-700 break-words" {...props} />,
                      code: ({node, inline, ...props}) => (
                        inline 
                          ? <code className="bg-gray-100 rounded px-1 py-0.5 text-sm break-words" {...props} />
                          : <code className="block bg-gray-100 rounded p-3 text-sm overflow-x-auto whitespace-pre-wrap break-words" {...props} />
                      ),
                    }}
                  >
                    {message.content}
                  </ReactMarkdown>
                ) : (
                  <p className="text-gray-700 break-words whitespace-pre-wrap">
                    {message.content}
                  </p>
                )}
              </div>
            </div>
          </div>
        ))}
      </div>

      {/* Footer notice */}
      <div className="fixed bottom-0 left-0 right-0 bg-white border-t border-gray-200 py-3">
        <div className="max-w-3xl mx-auto px-4 text-center">
          <p className="text-xs text-gray-500">
            ⚠️ This is a shared conversation from EquiExpert. For personalized advice, please{' '}
            <Link to="/" className="text-blue-600 hover:text-blue-700">
              start your own chat
            </Link>
            .
          </p>
        </div>
      </div>

      <style>{`
        .loading-dots {
          display: flex;
          gap: 4px;
        }
        .dot {
          width: 8px;
          height: 8px;
          background-color: #2563eb;
          border-radius: 50%;
          animation: bounce 1.4s infinite ease-in-out both;
        }
        .dot:nth-child(1) { animation-delay: -0.32s; }
        .dot:nth-child(2) { animation-delay: -0.16s; }
        @keyframes bounce {
          0%, 80%, 100% { 
            transform: scale(0);
          } 
          40% { 
            transform: scale(1.0);
          }
        }
      `}</style>
    </div>
  );
};

export default SharedChat; 