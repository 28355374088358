import React, { useState, useEffect } from "react";
import { User } from "firebase/auth";
import {
  collection,
  query,
  where,
  orderBy,
  onSnapshot,
  doc,
  setDoc,
  getDoc,
  getDocs,
} from "firebase/firestore";
import { signOut } from "firebase/auth";
import { auth, db } from "../firebase";
import { Link, useNavigate, useLocation } from "react-router-dom";
import { Pencil } from 'lucide-react';
import OnboardingModal from './OnboardingModal';

interface Post {
  id: string;
  text: string;
  imageUrl: string;
}

interface HorseProfile {
  horseName: string;
  horseDescription: string;
  ownerName: string;
  ownerInfo: string;
  updatedAt: string;
  horseHeight: string;
  horseWeight: string;
  horseBreed: string;
  horseAge: string;
  email?: string;
  // Use Firebase Auth timestamps
  metadata?: {
    createdAt?: Date;     // From auth.currentUser.metadata.creationTime
    lastLogin?: Date;     // From auth.currentUser.metadata.lastSignInTime
  };
}

export const summarizeHorseProfile = (profile: HorseProfile): string => {
  const details: string[] = [];

  // Add each piece of information if available
  if (profile.horseName) {
    details.push(`Dein Pferd heißt ${profile.horseName}`);
  }

  if (profile.horseBreed) {
    details.push(`Rasse: ${profile.horseBreed}`);
  }

  if (profile.horseAge) {
    details.push(`Alter: ${profile.horseAge} Jahre`);
  }

  if (profile.horseHeight) {
    details.push(`Stockmaß: ${profile.horseHeight}cm`);
  }

  if (profile.horseWeight) {
    details.push(`Gewicht: ${profile.horseWeight}kg`);
  }

  if (profile.horseDescription) {
    details.push(`\nBesondere Informationen: ${profile.horseDescription}`);
  }

  if (profile.ownerInfo) {
    details.push(`\nReiter Information: ${profile.ownerInfo}`);
  }

  // If no details are available
  if (details.length === 0) {
    return "Es liegen noch keine Details zu deinem Pferd vor.";
  }

  return details.join('\n');
};

const Profile: React.FC = () => {
  const [posts, setPosts] = useState<Post[]>([]);
  const [user, setUser] = useState<User | null>(null);
  const [horseProfile, setHorseProfile] = useState<HorseProfile>({
    horseName: "",
    horseDescription: "",
    ownerName: "",
    ownerInfo: "",
    updatedAt: "",
    horseHeight: "",
    horseWeight: "",
    horseBreed: "",
    horseAge: "",
  });
  const [isEditing, setIsEditing] = useState(false);
  const [isSaving, setIsSaving] = useState(false);
  const [error, setError] = useState<string | null>(null);
  const [showWelcomeMessage, setShowWelcomeMessage] = useState(false);
  const [showOnboarding, setShowOnboarding] = useState(false);

  // Router hooks
  const navigate = useNavigate();
  const location = useLocation();

  // Handle welcome message and editing mode
  useEffect(() => {
    const state = location.state as { showWelcomeMessage?: boolean; isNewUser?: boolean } | null;
    if (state?.showWelcomeMessage) {
      setShowWelcomeMessage(true);
      // Clear the state
      window.history.replaceState({}, document.title);
    }
    if (state?.isNewUser) {
      setIsEditing(true);
    }
  }, [location]);

  useEffect(() => {
    const unsubscribeAuth = auth.onAuthStateChanged(async (currentUser) => {
      setUser(currentUser);
      if (currentUser) {
        try {
          // Load horse profile with real-time updates
          const horseProfileRef = doc(db, "horseProfiles", currentUser.uid);
          const unsubscribeProfile = onSnapshot(horseProfileRef, (doc) => {
            if (doc.exists()) {
              setHorseProfile(doc.data() as HorseProfile);
            }
          }, (error) => {
            console.error("Error in profile snapshot:", error);
            setError("Failed to load profile updates. Please try again later.");
          });

          // Load posts with error handling
          const postsQuery = query(
            collection(db, "posts"),
            where("userId", "==", currentUser.uid)
          );

          const unsubscribePosts = onSnapshot(
            postsQuery,
            (snapshot) => {
              const newPosts = snapshot.docs.map((doc) => ({
                id: doc.id,
                ...doc.data(),
              })) as Post[];
              setPosts(newPosts.sort((a: any, b: any) => 
                b.createdAt?.toDate?.() - a.createdAt?.toDate?.() || 0
              ));
              setError(null);
            },
            (error) => {
              console.error("Error loading posts:", error);
              setError("Failed to load posts. Please try again later.");
            }
          );

          return () => {
            unsubscribePosts();
            unsubscribeProfile();
          };
        } catch (error) {
          console.error("Error in profile setup:", error);
          setError("Failed to load profile data. Please try again later.");
        }
      } else {
        setPosts([]);
        setHorseProfile({
          horseName: "",
          horseDescription: "",
          ownerName: "",
          ownerInfo: "",
          updatedAt: "",
          horseHeight: "",
          horseWeight: "",
          horseBreed: "",
          horseAge: "",
        });
      }
    });

    return () => unsubscribeAuth();
  }, []);

  const handleSignOut = async () => {
    try {
      await signOut(auth);
      navigate("/");
    } catch (error) {
      console.error("Error signing out:", error);
      setError("Failed to sign out. Please try again.");
    }
  };

  const handleSaveProfile = async () => {
    if (!user) return;

    try {
      setIsSaving(true);
      setError(null);

      // Validate input
      if (!horseProfile.horseName.trim()) {
        setError("Please enter your horse's name");
        return;
      }

      const horseProfileRef = doc(db, "horseProfiles", user.uid);
      const now = new Date();
      
      // Use Firebase Auth timestamps
      await setDoc(horseProfileRef, {
        ...horseProfile,
        horseName: horseProfile.horseName.trim(),
        horseDescription: horseProfile.horseDescription.trim(),
        updatedAt: now.toISOString(),
        email: user.email,
        metadata: {
          createdAt: new Date(user.metadata.creationTime),
          lastLogin: new Date(user.metadata.lastSignInTime)
        }
      }, { merge: true }); // Use merge to preserve any fields not in the form

      setIsEditing(false);
      setError(null);
    } catch (error) {
      console.error("Error saving horse profile:", error);
      setError("Failed to save profile. Please try again.");
    } finally {
      setIsSaving(false);
    }
  };

  // Handle onboarding completion for new users
  const handleOnboardingComplete = async (formData: HorseProfile) => {
    if (!user) return;

    try {
      const horseProfileRef = doc(db, "horseProfiles", user.uid);
      const now = new Date();

      // Use Firebase Auth timestamps
      await setDoc(horseProfileRef, {
        ...formData,
        updatedAt: now.toISOString(),
        email: user.email,
        metadata: {
          createdAt: new Date(user.metadata.creationTime),
          lastLogin: new Date(user.metadata.lastSignInTime)
        }
      }, { merge: true });

      // Update local state
      setHorseProfile({
        ...formData,
        updatedAt: now.toISOString(),
        email: user.email,
        metadata: {
          createdAt: new Date(user.metadata.creationTime),
          lastLogin: new Date(user.metadata.lastSignInTime)
        }
      });
      setShowOnboarding(false);
    } catch (error) {
      console.error("Error saving horse profile:", error);
      setError("Failed to save profile. Please try again.");
    }
  };

  // Check if user is new and show onboarding
  useEffect(() => {
    const checkNewUser = async () => {
      if (user) {
        const horseProfileRef = doc(db, "horseProfiles", user.uid);
        const profileDoc = await getDoc(horseProfileRef);

        // Show onboarding if profile doesn't exist or is empty
        if (!profileDoc.exists() || !profileDoc.data()?.horseName) {
          setShowOnboarding(true);
        }
      }
    };

    checkNewUser();
  }, [user]);

  if (!user) {
    return (
      <div className="min-h-screen bg-gray-50 p-6">
        <div className="max-w-2xl mx-auto mt-8 text-center">
          <h2 className="text-3xl font-bold text-gray-900 mb-6">Profile</h2>
          <p className="text-gray-600 mb-6">Please sign in to view your profile.</p>
          <Link
            to="/signin"
            className="inline-flex items-center px-6 py-3 bg-blue-600 text-white font-semibold rounded-lg hover:bg-blue-700 transition-colors duration-200"
          >
            Sign In
          </Link>
        </div>
      </div>
    );
  }

  return (
    <div className="min-h-screen bg-gray-50 p-6 pt-20">
      {/* Onboarding Modal for first-time users */}
      <OnboardingModal 
        isOpen={showOnboarding}
        onComplete={handleOnboardingComplete}
      />

      {showWelcomeMessage && (
        <div className="max-w-2xl mx-auto mb-6">
          <div className="bg-blue-50 border-l-4 border-blue-500 p-4 rounded-lg flex items-start">
            <div className="flex-1">
              <h3 className="text-blue-800 font-medium mb-1">
                Willkommen bei EquiExpert! 🐎
              </h3>
              <p className="text-blue-700">
                Füge mehr Informationen zu dir und deinem Pferd hinzu, 
                um EquiExpert die Arbeit zu erleichtern. So können wir 
                dir noch bessere und personalisiertere Unterstützung bieten.
              </p>
            </div>
            <button 
              onClick={() => setShowWelcomeMessage(false)}
              className="ml-4 text-blue-600 hover:text-blue-800"
            >
              ✕
            </button>
          </div>
        </div>
      )}

      <div className="max-w-2xl mx-auto">
        <h2 className="text-3xl font-bold text-gray-900 mb-6 text-center">Profile Settings</h2>

        {error && (
          <div className="mb-6 bg-red-50 border-l-4 border-red-500 p-4 rounded-lg">
            <p className="text-red-700">{error}</p>
          </div>
        )}

        <div className="bg-white rounded-2xl shadow-sm border border-gray-100 overflow-hidden">
          <div className="p-6">
            <div className="flex justify-between items-center mb-6">
              <h3 className="text-xl font-semibold text-gray-900">Profile Information</h3>
              {!isEditing && (
                <button
                  onClick={() => setIsEditing(true)}
                  className="inline-flex items-center space-x-2 text-blue-600 hover:text-blue-700 transition-colors"
                >
                  <Pencil size={16} />
                  <span>Edit</span>
                </button>
              )}
            </div>

            {isEditing ? (
              <div className="space-y-6">
                {/* Owner Information Section */}
                <div className="border-b border-gray-200 pb-6">
                  <h4 className="text-lg font-medium text-gray-900 mb-4">Besitzer/Reiter Information</h4>
                  <div className="space-y-4">
                    <div>
                      <label className="block text-sm font-medium text-gray-700 mb-2">
                        Dein Name
                      </label>
                      <input
                        type="text"
                        value={horseProfile.ownerName}
                        onChange={(e) =>
                          setHorseProfile((prev) => ({
                            ...prev,
                            ownerName: e.target.value,
                          }))
                        }
                        className="w-full px-4 py-3 border border-gray-200 rounded-lg focus:ring-2 focus:ring-blue-500 focus:border-transparent transition-shadow duration-200"
                        placeholder="Dein Name (optional)"
                      />
                    </div>
                    <div>
                      <label className="block text-sm font-medium text-gray-700 mb-2">
                        Über dich
                      </label>
                      <textarea
                        value={horseProfile.ownerInfo}
                        onChange={(e) =>
                          setHorseProfile((prev) => ({
                            ...prev,
                            ownerInfo: e.target.value,
                          }))
                        }
                        rows={3}
                        className="w-full px-4 py-3 border border-gray-200 rounded-lg focus:ring-2 focus:ring-blue-500 focus:border-transparent transition-shadow duration-200"
                        placeholder="Deine Reiterfahrung, Ziele, besondere Interessen etc."
                      />
                    </div>
                  </div>
                </div>

                {/* Horse Information Section */}
                <div>
                  <h4 className="text-lg font-medium text-gray-900 mb-4">Pferd Information</h4>
                  <div className="space-y-4">
                    <div>
                      <label className="block text-sm font-medium text-gray-700 mb-2">
                        Name des Pferdes <span className="text-red-500">*</span>
                      </label>
                      <input
                        type="text"
                        value={horseProfile.horseName}
                        onChange={(e) =>
                          setHorseProfile((prev) => ({
                            ...prev,
                            horseName: e.target.value,
                          }))
                        }
                        className="w-full px-4 py-3 border border-gray-200 rounded-lg focus:ring-2 focus:ring-blue-500 focus:border-transparent transition-shadow duration-200"
                        placeholder="Name deines Pferdes"
                        required
                      />
                    </div>
                    <div className="grid grid-cols-2 gap-4 mb-4">
                      <div>
                        <label className="block text-sm font-medium text-gray-700 mb-2">
                          Rasse
                        </label>
                        <select
                          value={horseProfile.horseBreed}
                          onChange={(e) =>
                            setHorseProfile((prev) => ({
                              ...prev,
                              horseBreed: e.target.value,
                            }))
                          }
                          className="w-full px-4 py-3 border border-gray-200 rounded-lg focus:ring-2 focus:ring-blue-500 focus:border-transparent transition-shadow duration-200"
                        >
                          <option value="">Rasse auswählen</option>
                          <option value="Vollblut">Vollblut</option>
                          <option value="Warmblut">Warmblut</option>
                          <option value="Pony">Pony</option>
                          <option value="Kaltblut">Kaltblut</option>
                          <option value="Araber">Araber</option>
                        </select>
                      </div>
                      <div>
                        <label className="block text-sm font-medium text-gray-700 mb-2">
                          Alter
                        </label>
                        <input
                          type="number"
                          value={horseProfile.horseAge}
                          onChange={(e) =>
                            setHorseProfile((prev) => ({
                              ...prev,
                              horseAge: e.target.value,
                            }))
                          }
                          className="w-full px-4 py-3 border border-gray-200 rounded-lg focus:ring-2 focus:ring-blue-500 focus:border-transparent transition-shadow duration-200"
                          placeholder="z.B. 12"
                          min="0"
                          max="40"
                        />
                      </div>
                    </div>
                    <div className="grid grid-cols-2 gap-4">
                      <div>
                        <label className="block text-sm font-medium text-gray-700 mb-2">
                          Stockmaß (cm)
                        </label>
                        <input
                          type="number"
                          value={horseProfile.horseHeight}
                          onChange={(e) =>
                            setHorseProfile((prev) => ({
                              ...prev,
                              horseHeight: e.target.value,
                            }))
                          }
                          className="w-full px-4 py-3 border border-gray-200 rounded-lg focus:ring-2 focus:ring-blue-500 focus:border-transparent transition-shadow duration-200"
                          placeholder="z.B. 165"
                        />
                      </div>
                      <div>
                        <label className="block text-sm font-medium text-gray-700 mb-2">
                          Gewicht (kg)
                        </label>
                        <input
                          type="number"
                          value={horseProfile.horseWeight}
                          onChange={(e) =>
                            setHorseProfile((prev) => ({
                              ...prev,
                              horseWeight: e.target.value,
                            }))
                          }
                          className="w-full px-4 py-3 border border-gray-200 rounded-lg focus:ring-2 focus:ring-blue-500 focus:border-transparent transition-shadow duration-200"
                          placeholder="z.B. 500"
                        />
                      </div>
                    </div>
                    <div>
                      <label className="block text-sm font-medium text-gray-700 mb-2">
                        Über dein Pferd
                      </label>
                      <textarea
                        value={horseProfile.horseDescription}
                        onChange={(e) =>
                          setHorseProfile((prev) => ({
                            ...prev,
                            horseDescription: e.target.value,
                          }))
                        }
                        rows={4}
                        className="w-full px-4 py-3 border border-gray-200 rounded-lg focus:ring-2 focus:ring-blue-500 focus:border-transparent transition-shadow duration-200"
                        placeholder="Beschreibe dein Pferd (Alter, Rasse, Temperament, besondere Bedürfnisse etc.)"
                      />
                    </div>
                  </div>
                </div>

                {/* Save/Cancel Buttons moved to bottom */}
                <div className="pt-6 border-t border-gray-200">
                  <div className="flex justify-end space-x-3">
                    <button
                      onClick={() => {
                        setIsEditing(false);
                        setError(null);
                      }}
                      className="inline-flex items-center px-4 py-2 bg-gray-600 text-white font-medium rounded-lg hover:bg-gray-700 transition-colors duration-200"
                    >
                      Cancel
                    </button>
                    <button
                      onClick={handleSaveProfile}
                      disabled={isSaving}
                      className="inline-flex items-center px-4 py-2 bg-green-600 text-white font-medium rounded-lg hover:bg-green-700 transition-colors duration-200 disabled:opacity-50"
                    >
                      {isSaving ? "Saving..." : "Save"}
                    </button>
                  </div>
                </div>
              </div>
            ) : (
              <div className="space-y-8">
                {/* Owner Information Display */}
                <div className="border-b border-gray-200 pb-6">
                  <h4 className="text-lg font-medium text-gray-900 mb-4">Besitzer/Reiter Information</h4>
                  <div className="space-y-4">
                    <div>
                      <h5 className="text-sm font-medium text-gray-600 mb-2">Name</h5>
                      <p className="text-gray-900">
                        {horseProfile.ownerName || "Nicht angegeben"}
                      </p>
                    </div>
                    <div>
                      <h5 className="text-sm font-medium text-gray-600 mb-2">Über dich</h5>
                      <p className="text-gray-900 whitespace-pre-wrap">
                        {horseProfile.ownerInfo || "Keine Information angegeben"}
                      </p>
                    </div>
                  </div>
                </div>

                {/* Horse Information Display */}
                <div>
                  <h4 className="text-lg font-medium text-gray-900 mb-4">Pferd Information</h4>
                  <div className="space-y-4">
                    <div>
                      <h5 className="text-sm font-medium text-gray-600 mb-2">Name des Pferdes</h5>
                      <p className="text-gray-900">
                        {horseProfile.horseName || "Nicht angegeben"}
                      </p>
                    </div>

                    <div>
                      <h5 className="text-sm font-medium text-gray-600 mb-2">Rasse</h5>
                      <p className="text-gray-900">
                        {horseProfile.horseBreed || "Nicht angegeben"}
                      </p>
                    </div>

                    <div>
                      <h5 className="text-sm font-medium text-gray-600 mb-2">Alter</h5>
                      <p className="text-gray-900">
                        {horseProfile.horseAge ? `${horseProfile.horseAge} Jahre` : "Nicht angegeben"}
                      </p>
                    </div>

                    <div>
                      <h5 className="text-sm font-medium text-gray-600 mb-2">Stockmaß</h5>
                      <p className="text-gray-900">
                        {horseProfile.horseHeight ? `${horseProfile.horseHeight} cm` : "Nicht angegeben"}
                      </p>
                    </div>

                    <div>
                      <h5 className="text-sm font-medium text-gray-600 mb-2">Gewicht</h5>
                      <p className="text-gray-900">
                        {horseProfile.horseWeight ? `${horseProfile.horseWeight} kg` : "Nicht angegeben"}
                      </p>
                    </div>

                    <div>
                      <h5 className="text-sm font-medium text-gray-600 mb-2">Über dein Pferd</h5>
                      <p className="text-gray-900 whitespace-pre-wrap">
                        {horseProfile.horseDescription || "Keine Beschreibung angegeben"}
                      </p>
                    </div>

                    {horseProfile.updatedAt && (
                      <div className="pt-4 border-t border-gray-200">
                        <p className="text-sm text-gray-500">
                          Zuletzt aktualisiert: {new Date(horseProfile.updatedAt).toLocaleDateString()}
                        </p>
                      </div>
                    )}
                  </div>
                </div>
              </div>
            )}
          </div>
        </div>

        <div className="mt-8 text-center">
          <button
            onClick={handleSignOut}
            className="inline-flex items-center px-6 py-3 bg-red-600 text-white font-semibold rounded-lg hover:bg-red-700 transition-colors duration-200"
          >
            Abmelden
          </button>

          {/* Legal Links Section */}
          <div className="mt-6 flex justify-center items-center gap-4 text-sm text-gray-500">
            <Link to="/agb" className="hover:text-gray-700 transition-colors">
              AGB
            </Link>
            <span className="text-gray-300">|</span>
            <Link to="/impressum" className="hover:text-gray-700 transition-colors">
              Impressum
            </Link>
            <span className="text-gray-300">|</span>
            <Link to="/datenschutz" className="hover:text-gray-700 transition-colors">
              Datenschutz
            </Link>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Profile;
