import { useRef, useState, useEffect } from 'react';
import { db } from '../firebase';
import { collection, addDoc, query, orderBy, getDocs, where, writeBatch } from 'firebase/firestore';

interface GameState {
  horse: {
    x: number;
    y: number;
    width: number;
    height: number;
    jumping: boolean;
    jumpForce: number;
    frameCount: number;
    animationFrame: number;
    particleTime: number;
    particles: Particle[];
  };
  obstacles: Obstacle[];
  gravity: number;
  jumpPower: number;
  gameSpeed: number;
  lastObstacleTime: number;
}

interface Particle {
  x: number;
  y: number;
  vx: number;
  vy: number;
  life: number;
  color: string;
}

interface Obstacle {
  x: number;
  y: number;
  width: number;
  height: number;
  passed?: boolean;
}

interface LeaderboardEntry {
  nickname: string;
  highestScore: number;
  totalGames: number;
  lastPlayed: number;
}

interface GameScore {
  nickname: string;
  score: number;
  timestamp: number;
}

const USERS_PER_PAGE = 30;
const CANVAS_HEIGHT = 300; // Fixed canvas height
const CANVAS_MIN_WIDTH = 320; // Minimum canvas width for mobile

// Function to seed initial leaderboard data
const seedLeaderboardData = async () => {
  const germanHorseNames = [
    'Blitz', 'Donner', 'Sturm', 'Luna', 'Stella', 'Max', 'Bella', 'Rocky', 
    'Shadow', 'Lucky', 'Charlie', 'Angel', 'Spirit', 'Thunder', 'Star',
    'Pegasus', 'Phoenix', 'Zeus', 'Apollo', 'Athena', 'Hermes', 'Ares',
    'Hades', 'Poseidon', 'Artemis', 'Helios', 'Atlas', 'Echo', 'Iris',
    'Nike', 'Titan', 'Orion', 'Comet', 'Nova', 'Meteor', 'Galaxy',
    'Cosmos', 'Nebula', 'Astro', 'Rocket', 'Storm', 'Cloud', 'Rain',
    'Wind', 'Fire', 'Earth', 'Water', 'Light', 'Dark', 'Magic'
  ];

  const scoresRef = collection(db, 'scores');

  // Check if we already have data
  const existingData = await getDocs(scoresRef);
  if (!existingData.empty) {
    console.log('Leaderboard already has data');
    return;
  }

  // Generate 3-4 scores for each nickname
  for (const nickname of germanHorseNames) {
    const numberOfGames = Math.floor(Math.random() * 2) + 3; // 3-4 games each
    const baseTime = Date.now() - (30 * 24 * 60 * 60 * 1000); // Start from 30 days ago

    for (let i = 0; i < numberOfGames; i++) {
      const score = Math.floor(Math.random() * 12) + 1; // Score between 1-12
      const timestamp = baseTime + (i * 24 * 60 * 60 * 1000); // Space games 1 day apart

      await addDoc(scoresRef, {
        nickname,
        score,
        timestamp
      });
    }
  }

  console.log('Seeded leaderboard with initial data');
};

const getMedalEmoji = (rank: number) => {
  switch (rank) {
    case 1:
      return '🥇';
    case 2:
      return '🥈';
    case 3:
      return '🥉';
    default:
      return '';
  }
};

const HorseGame: React.FC = () => {
  const canvasRef = useRef<HTMLCanvasElement>(null);
  const containerRef = useRef<HTMLDivElement>(null);
  const [gameOver, setGameOver] = useState(false);
  const [score, setScore] = useState(0);
  const [nickname, setNickname] = useState('');
  const [leaderboard, setLeaderboard] = useState<LeaderboardEntry[]>([]);
  const [isPlaying, setIsPlaying] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const [nicknameError, setNicknameError] = useState<string>('');
  const [isCheckingNickname, setIsCheckingNickname] = useState(false);
  const [isEditingNickname, setIsEditingNickname] = useState(false);
  const [newNickname, setNewNickname] = useState('');
  const [canvasSize, setCanvasSize] = useState({ width: 800, height: CANVAS_HEIGHT });
  
  // Load leaderboard from Firebase on mount
  useEffect(() => {
    loadLeaderboard();
  }, []);

  // Load saved nickname from localStorage
  useEffect(() => {
    const savedNickname = localStorage.getItem('horseGameNickname');
    if (savedNickname) {
      setNickname(savedNickname);
    }
  }, []);

  // Call seeding function once on mount
  useEffect(() => {
    seedLeaderboardData();
  }, []);

  // Handle canvas resize
  useEffect(() => {
    const handleResize = () => {
      if (containerRef.current) {
        const containerWidth = containerRef.current.offsetWidth;
        // Maintain minimum width while keeping aspect ratio
        const newWidth = Math.max(CANVAS_MIN_WIDTH, Math.min(800, containerWidth - 32));
        
        setCanvasSize({
          width: newWidth,
          height: CANVAS_HEIGHT // Keep height constant
        });
      }
    };

    handleResize();
    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  const loadLeaderboard = async () => {
    try {
      setIsLoading(true);
      const scoresRef = collection(db, 'scores');
      const scoresSnapshot = await getDocs(scoresRef);
      
      // Create a map to track each user's stats
      const userStats = new Map<string, LeaderboardEntry>();
      
      scoresSnapshot.forEach((doc) => {
        const scoreData = doc.data() as GameScore;
        const existingStats = userStats.get(scoreData.nickname);
        
        if (existingStats) {
          userStats.set(scoreData.nickname, {
            nickname: scoreData.nickname,
            highestScore: Math.max(existingStats.highestScore, scoreData.score),
            totalGames: existingStats.totalGames + 1,
            lastPlayed: Math.max(existingStats.lastPlayed, scoreData.timestamp)
          });
        } else {
          userStats.set(scoreData.nickname, {
            nickname: scoreData.nickname,
            highestScore: scoreData.score,
            totalGames: 1,
            lastPlayed: scoreData.timestamp
          });
        }
      });

      // Convert map to array and sort by highest score
      const leaderboardArray = Array.from(userStats.values())
        .sort((a, b) => b.highestScore - a.highestScore);

      setLeaderboard(leaderboardArray);
      setTotalPages(Math.ceil(leaderboardArray.length / USERS_PER_PAGE));
      setIsLoading(false);
    } catch (error) {
      console.error('Error loading leaderboard:', error);
      setIsLoading(false);
    }
  };

  // Get current page items
  const getCurrentPageItems = () => {
    const startIndex = (currentPage - 1) * USERS_PER_PAGE;
    const endIndex = startIndex + USERS_PER_PAGE;
    return leaderboard.slice(startIndex, endIndex);
  };

  // Pagination controls
  const handlePageChange = (newPage: number) => {
    setCurrentPage(newPage);
  };

  const PaginationControls = () => {
    if (totalPages <= 1) return null;

    return (
      <div className="flex justify-center items-center space-x-2 mt-4 pb-2">
        <button
          onClick={() => handlePageChange(1)}
          disabled={currentPage === 1}
          className={`px-3 py-1 rounded ${
            currentPage === 1
              ? 'bg-gray-200 text-gray-500 cursor-not-allowed'
              : 'bg-blue-500 text-white hover:bg-blue-600'
          }`}
        >
          ««
        </button>
        <button
          onClick={() => handlePageChange(currentPage - 1)}
          disabled={currentPage === 1}
          className={`px-3 py-1 rounded ${
            currentPage === 1
              ? 'bg-gray-200 text-gray-500 cursor-not-allowed'
              : 'bg-blue-500 text-white hover:bg-blue-600'
          }`}
        >
          «
        </button>
        
        <span className="px-4 py-1">
          Seite {currentPage} von {totalPages}
        </span>
        
        <button
          onClick={() => handlePageChange(currentPage + 1)}
          disabled={currentPage === totalPages}
          className={`px-3 py-1 rounded ${
            currentPage === totalPages
              ? 'bg-gray-200 text-gray-500 cursor-not-allowed'
              : 'bg-blue-500 text-white hover:bg-blue-600'
          }`}
        >
          »
        </button>
        <button
          onClick={() => handlePageChange(totalPages)}
          disabled={currentPage === totalPages}
          className={`px-3 py-1 rounded ${
            currentPage === totalPages
              ? 'bg-gray-200 text-gray-500 cursor-not-allowed'
              : 'bg-blue-500 text-white hover:bg-blue-600'
          }`}
        >
          »»
        </button>
      </div>
    );
  };

  const updateLeaderboard = async (newScore: number) => {
    try {
      // Add new score to Firebase
      const scoresRef = collection(db, 'scores');
      await addDoc(scoresRef, {
        nickname,
        score: newScore,
        timestamp: Date.now()
      });

      // Reload leaderboard
      await loadLeaderboard();
    } catch (error) {
      console.error('Error updating leaderboard:', error);
    }
  };

  // Game state
  const gameState = useRef<GameState>({
    horse: {
      x: 50,
      y: 200,
      width: 100,
      height: 70,
      jumping: false,
      jumpForce: 0,
      frameCount: 0,
      animationFrame: 0,
      particleTime: 0,
      particles: []
    },
    obstacles: [],
    gravity: 0.6,
    jumpPower: -15,
    gameSpeed: 5,
    lastObstacleTime: 0
  });

  // Shared jump logic
  const handleJump = () => {
    const state = gameState.current;
    if (!state.horse.jumping) {
      state.horse.jumping = true;
      state.horse.jumpForce = state.jumpPower;
    }
  };

  // Main game loop effect
  useEffect(() => {
    if (!isPlaying) return;

    const canvas = canvasRef.current;
    if (!canvas) return;
    
    const ctx = canvas.getContext('2d');
    if (!ctx) return;

    let animationFrameId: number;
    let lastTime = 0;

    const createParticle = (x: number, y: number): Particle => ({
      x,
      y,
      vx: (Math.random() - 0.5) * 3,
      vy: (Math.random() - 0.5) * 3,
      life: 1,
      color: `hsl(${Math.random() * 60 + 240}, 100%, 50%)`
    });

    const drawHorse = (x: number, y: number, frame: number, jumping: boolean) => {
      ctx.save();
      ctx.translate(x, y);

      // Create flame effect
      const flameGradient = ctx.createLinearGradient(-20, 0, 100, 0);
      flameGradient.addColorStop(0, 'rgba(0, 0, 255, 0)');
      flameGradient.addColorStop(0.5, 'rgba(0, 128, 255, 0.3)');
      flameGradient.addColorStop(1, 'rgba(0, 255, 255, 0)');

      // Magical aura
      ctx.beginPath();
      ctx.fillStyle = flameGradient;
      ctx.filter = 'blur(5px)';
      ctx.ellipse(30, 0, 60, 40, 0, 0, Math.PI * 2);
      ctx.fill();
      ctx.filter = 'none';

      // Body - crystalline effect
      ctx.fillStyle = '#4169E1';
      ctx.beginPath();
      ctx.moveTo(0, 0);
      ctx.lineTo(60, -10);
      ctx.lineTo(80, 0);
      ctx.lineTo(60, 10);
      ctx.closePath();
      ctx.fill();

      // Sparkle details
      ctx.strokeStyle = '#87CEFA';
      ctx.lineWidth = 2;
      ctx.beginPath();
      ctx.moveTo(10, 0);
      ctx.lineTo(70, -5);
      ctx.moveTo(20, 5);
      ctx.lineTo(75, 0);
      ctx.stroke();

      // Flame mane
      const maneOffset = Math.sin(frame * 0.2) * 3;
      for (let i = 0; i < 8; i++) {
        ctx.fillStyle = `hsla(${240 + i * 10}, 100%, 50%, 0.5)`;
        ctx.beginPath();
        ctx.moveTo(50 + i * 3, -20 + maneOffset);
        ctx.quadraticCurveTo(
          45 + i * 3,
          -35 + Math.sin(frame * 0.1 + i) * 5,
          40 + i * 3,
          -15 + maneOffset
        );
        ctx.quadraticCurveTo(45 + i * 3, -25, 50 + i * 3, -20 + maneOffset);
        ctx.fill();
      }

      // Crystal horn
      ctx.fillStyle = '#E0FFFF';
      ctx.beginPath();
      ctx.moveTo(75, -45);
      ctx.lineTo(85, -60);
      ctx.lineTo(80, -45);
      ctx.fill();

      // Glowing eyes
      ctx.fillStyle = '#00FFFF';
      ctx.beginPath();
      ctx.arc(70, -35, 3, 0, Math.PI * 2);
      ctx.fill();
      ctx.fillStyle = '#FFFFFF';
      ctx.beginPath();
      ctx.arc(70, -35, 1.5, 0, Math.PI * 2);
      ctx.fill();

      // Energy legs
      const legOffset = Math.sin(frame * 0.5) * 5;
      ctx.strokeStyle = '#00FFFF';
      ctx.lineWidth = 3;
      
      // Front legs
      ctx.beginPath();
      ctx.moveTo(45, 10);
      ctx.lineTo(45, 40 + legOffset);
      ctx.moveTo(55, 10);
      ctx.lineTo(55, 40 - legOffset);
      ctx.stroke();

      // Back legs
      ctx.beginPath();
      ctx.moveTo(15, 10);
      ctx.lineTo(15, 40 - legOffset);
      ctx.moveTo(5, 10);
      ctx.lineTo(5, 40 + legOffset);
      ctx.stroke();

      // Glow effect
      ctx.shadowColor = '#00FFFF';
      ctx.shadowBlur = 20;
      ctx.strokeStyle = '#FFFFFF';
      ctx.lineWidth = 1;
      ctx.stroke();

      ctx.restore();
    };

    const gameLoop = (timestamp: number) => {
      if (!lastTime) lastTime = timestamp;
      const deltaTime = timestamp - lastTime;
      lastTime = timestamp;

      // Clear and setup background
      ctx.clearRect(0, 0, canvas.width, canvas.height);
      ctx.fillStyle = '#000033';
      ctx.fillRect(0, 0, canvas.width, canvas.height);

      // Stars
      for (let i = 0; i < 100; i++) {
        ctx.fillStyle = `rgba(255, 255, 255, ${Math.random()})`;
        ctx.fillRect(
          Math.random() * canvas.width,
          Math.random() * canvas.height,
          1,
          1
        );
      }

      // Update horse
      const state = gameState.current;
      state.horse.frameCount += 1;
      if (state.horse.frameCount % 5 === 0) {
        state.horse.animationFrame += 1;
      }

      // Particles
      state.horse.particleTime += 1;
      if (state.horse.particleTime % 2 === 0) {
        state.horse.particles.push(
          createParticle(
            state.horse.x + 20 + Math.random() * 60,
            state.horse.y + Math.random() * 40
          )
        );
      }

      state.horse.particles = state.horse.particles.filter(particle => {
        particle.x += particle.vx;
        particle.y += particle.vy;
        particle.life -= 0.02;
        
        if (particle.life > 0) {
          ctx.fillStyle = particle.color;
          ctx.globalAlpha = particle.life;
          ctx.fillRect(particle.x, particle.y, 2, 2);
          ctx.globalAlpha = 1;
          return true;
        }
        return false;
      });

      if (state.horse.jumping) {
        state.horse.jumpForce += state.gravity;
        state.horse.y += state.horse.jumpForce;

        if (state.horse.y > 200) {
          state.horse.y = 200;
          state.horse.jumping = false;
          state.horse.jumpForce = 0;
        }
      }

      // Generate obstacles
      if (timestamp - state.lastObstacleTime > 2000) {
        state.obstacles.push({
          x: canvas.width,
          y: 220,
          width: 40,
          height: 50
        });
        state.lastObstacleTime = timestamp;
      }

      // Update obstacles
      state.obstacles = state.obstacles.filter(obstacle => {
        obstacle.x -= state.gameSpeed;
        
        // Collision check
        if (
          state.horse.x < obstacle.x + obstacle.width &&
          state.horse.x + state.horse.width > obstacle.x &&
          state.horse.y + state.horse.height > obstacle.y
        ) {
          setGameOver(true);
          return false;
        }

        // Score update
        if (obstacle.x + obstacle.width < state.horse.x && !obstacle.passed) {
          setScore(prev => prev + 1);
          obstacle.passed = true;
        }

        // Draw crystal obstacle
        ctx.fillStyle = '#4169E1';
        ctx.strokeStyle = '#87CEFA';
        ctx.lineWidth = 2;
        
        ctx.beginPath();
        ctx.moveTo(obstacle.x, obstacle.y + obstacle.height);
        ctx.lineTo(obstacle.x + obstacle.width/2, obstacle.y);
        ctx.lineTo(obstacle.x + obstacle.width, obstacle.y + obstacle.height);
        ctx.closePath();
        ctx.fill();
        ctx.stroke();

        ctx.shadowColor = '#00FFFF';
        ctx.shadowBlur = 10;
        ctx.stroke();
        ctx.shadowBlur = 0;
        
        return obstacle.x > -obstacle.width;
      });

      // Draw horse
      drawHorse(state.horse.x, state.horse.y, state.horse.animationFrame, state.horse.jumping);

      // Ground
      const groundGradient = ctx.createLinearGradient(0, 250, 0, 300);
      groundGradient.addColorStop(0, '#000066');
      groundGradient.addColorStop(1, '#4169E1');
      ctx.fillStyle = groundGradient;
      ctx.fillRect(0, 250, canvas.width, 50);

      // Ground sparkles
      for (let i = 0; i < canvas.width; i += 20) {
        ctx.fillStyle = `rgba(255, 255, 255, ${Math.random() * 0.5})`;
        ctx.beginPath();
        ctx.arc(i, 250 + Math.random() * 50, 1, 0, Math.PI * 2);
        ctx.fill();
      }

      if (!gameOver && isPlaying) {
        animationFrameId = requestAnimationFrame(gameLoop);
      }
    };

    const handleKeyPress = (e: KeyboardEvent) => {
      if (e.code === 'Space') {
        handleJump();
      }
    };

    canvas.addEventListener('click', handleJump);
    window.addEventListener('keydown', handleKeyPress);

    // Start the game loop
    animationFrameId = requestAnimationFrame(gameLoop);

    return () => {
      cancelAnimationFrame(animationFrameId);
      canvas.removeEventListener('click', handleJump);
      window.removeEventListener('keydown', handleKeyPress);
    };
  }, [isPlaying, gameOver]); // Add isPlaying to dependencies

  const checkNicknameAvailability = async (nicknameToCheck: string): Promise<boolean> => {
    if (!nicknameToCheck.trim()) return false;
    
    try {
      const scoresRef = collection(db, 'scores');
      const q = query(scoresRef, where('nickname', '==', nicknameToCheck));
      const querySnapshot = await getDocs(q);
      
      return querySnapshot.empty;
    } catch (error) {
      console.error('Error checking nickname:', error);
      return false;
    }
  };

  const handleNicknameChange = async () => {
    if (!newNickname.trim()) {
      setNicknameError('Bitte geben Sie einen Nicknamen ein!');
      return;
    }

    setIsCheckingNickname(true);
    const isAvailable = await checkNicknameAvailability(newNickname);
    setIsCheckingNickname(false);

    if (!isAvailable) {
      setNicknameError('Dieser Nickname ist bereits vergeben!');
      return;
    }

    try {
      // Update all existing scores with the new nickname
      const scoresRef = collection(db, 'scores');
      const oldScoresQuery = query(scoresRef, where('nickname', '==', nickname));
      const querySnapshot = await getDocs(oldScoresQuery);
      
      const batch = writeBatch(db);
      querySnapshot.forEach((doc) => {
        batch.update(doc.ref, { nickname: newNickname });
      });
      await batch.commit();

      // Update local state and storage
      localStorage.setItem('horseGameNickname', newNickname);
      setNickname(newNickname);
      setIsEditingNickname(false);
      setNewNickname('');
      setNicknameError('');
      
      // Reload leaderboard to reflect changes
      await loadLeaderboard();
    } catch (error) {
      console.error('Error updating nickname:', error);
      setNicknameError('Fehler beim Ändern des Nicknames. Bitte versuchen Sie es später erneut.');
    }
  };

  const handleGameStart = async () => {
    if (!nickname.trim()) {
      setNicknameError('Bitte geben Sie einen Nicknamen ein!');
      return;
    }

    // Only check availability if this is a new nickname
    if (!localStorage.getItem('horseGameNickname')) {
      setIsCheckingNickname(true);
      const isAvailable = await checkNicknameAvailability(nickname);
      setIsCheckingNickname(false);

      if (!isAvailable) {
        setNicknameError('Dieser Nickname ist bereits vergeben!');
        return;
      }

      // Save the new nickname
      localStorage.setItem('horseGameNickname', nickname);
    }

    setIsPlaying(true);
    setGameOver(false);
    setScore(0);
    gameState.current = {
      horse: {
        x: 50,
        y: 200,
        width: 100,
        height: 70,
        jumping: false,
        jumpForce: 0,
        frameCount: 0,
        animationFrame: 0,
        particleTime: 0,
        particles: []
      },
      obstacles: [],
      gravity: 0.6,
      jumpPower: -15,
      gameSpeed: 5,
      lastObstacleTime: 0
    };
  };

  useEffect(() => {
    if (gameOver && score > 0) {
      updateLeaderboard(score);
    }
  }, [gameOver, score]);

  // Calculate true rank considering ties
  const calculateRank = (currentScore: number, index: number) => {
    const allScores = leaderboard.map(entry => entry.highestScore);
    const uniqueScores = [...new Set(allScores)].sort((a, b) => b - a);
    return uniqueScores.indexOf(currentScore) + 1;
  };

  // Mobile jump button handlers
  const handleJumpButtonTouch = (e: React.TouchEvent) => {
    e.preventDefault(); // Prevent double triggers
    handleJump();
  };

  const handleJumpButtonClick = (e: React.MouseEvent) => {
    e.preventDefault();
    handleJump();
  };

  return (
    <div className="min-h-screen bg-gray-50 p-6 pt-20">
      <div className="max-w-4xl mx-auto">
        <h2 className="text-3xl font-bold text-gray-900 mb-6 text-center">Magisches Pferdespiel</h2>
        
        <div className="bg-white rounded-2xl shadow-sm border border-gray-100 overflow-hidden p-6">
          {!isPlaying ? (
            <div className="flex flex-col items-center gap-4 p-6">
              <h3 className="text-xl font-semibold">Willkommen zum Spiel!</h3>
              {!isEditingNickname ? (
                <div className="w-full max-w-md">
                  {nickname ? (
                    <div className="flex flex-col items-center gap-4">
                      <p className="text-lg">Dein aktueller Nickname: <strong>{nickname}</strong></p>
                      <button
                        onClick={() => {
                          setIsEditingNickname(true);
                          setNewNickname(nickname);
                        }}
                        className="text-blue-600 hover:text-blue-800 underline"
                      >
                        Nickname ändern
                      </button>
                    </div>
                  ) : (
                    <>
                      <label className="block text-sm font-medium text-gray-700 mb-2">
                        Dein Nickname
                      </label>
                      <input
                        type="text"
                        value={nickname}
                        onChange={(e) => {
                          setNickname(e.target.value);
                          setNicknameError('');
                        }}
                        className={`w-full px-4 py-2 border rounded-md focus:ring-2 focus:ring-blue-500 focus:border-transparent ${
                          nicknameError ? 'border-red-500' : 'border-gray-300'
                        }`}
                        placeholder="Gib deinen Nickname ein"
                      />
                    </>
                  )}
                </div>
              ) : (
                <div className="w-full max-w-md">
                  <label className="block text-sm font-medium text-gray-700 mb-2">
                    Neuer Nickname
                  </label>
                  <input
                    type="text"
                    value={newNickname}
                    onChange={(e) => {
                      setNewNickname(e.target.value);
                      setNicknameError('');
                    }}
                    className={`w-full px-4 py-2 border rounded-md focus:ring-2 focus:ring-blue-500 focus:border-transparent ${
                      nicknameError ? 'border-red-500' : 'border-gray-300'
                    }`}
                    placeholder="Gib deinen neuen Nickname ein"
                  />
                  {nicknameError && (
                    <p className="mt-1 text-sm text-red-600">{nicknameError}</p>
                  )}
                  <div className="flex justify-end gap-2 mt-4">
                    <button
                      onClick={() => {
                        setIsEditingNickname(false);
                        setNewNickname('');
                        setNicknameError('');
                      }}
                      className="px-4 py-2 text-gray-600 hover:text-gray-800"
                    >
                      Abbrechen
                    </button>
                    <button
                      onClick={handleNicknameChange}
                      disabled={isCheckingNickname}
                      className={`bg-blue-500 text-white px-4 py-2 rounded-md hover:bg-blue-600 transition-colors ${
                        isCheckingNickname ? 'opacity-50 cursor-not-allowed' : ''
                      }`}
                    >
                      {isCheckingNickname ? 'Überprüfe...' : 'Speichern'}
                    </button>
                  </div>
                </div>
              )}
              <button
                onClick={handleGameStart}
                disabled={isCheckingNickname || isEditingNickname}
                className={`bg-blue-500 text-white px-6 py-2 rounded-md hover:bg-blue-600 transition-colors ${
                  (isCheckingNickname || isEditingNickname) ? 'opacity-50 cursor-not-allowed' : ''
                }`}
              >
                {isCheckingNickname ? 'Überprüfe Nickname...' : 'Spiel starten'}
              </button>
            </div>
          ) : (
            <div className="flex flex-col items-center gap-4" ref={containerRef}>
              <div className="text-xl font-bold">
                {nickname} - Score: {score}
              </div>
              <div className="relative w-full flex justify-center">
                <div className="relative inline-block" style={{ minWidth: CANVAS_MIN_WIDTH }}>
                  <canvas
                    ref={canvasRef}
                    width={canvasSize.width}
                    height={canvasSize.height}
                    className="border border-gray-300 rounded-lg bg-[#000033]"
                    style={{
                      width: '100%',
                      height: CANVAS_HEIGHT,
                      minWidth: CANVAS_MIN_WIDTH
                    }}
                  />
                  {/* Mobile Jump Button - Only show if game is not over */}
                  {!gameOver && (
                    <button
                      onTouchStart={handleJumpButtonTouch}
                      onClick={handleJumpButtonClick}
                      className="md:hidden absolute bottom-4 right-4 w-16 h-16 bg-blue-500 rounded-full flex items-center justify-center shadow-lg active:bg-blue-600 active:transform active:scale-95 transition-all z-10 touch-manipulation"
                      aria-label="Jump"
                    >
                      <svg 
                        xmlns="http://www.w3.org/2000/svg" 
                        className="w-8 h-8 text-white"
                        viewBox="0 0 24 24" 
                        fill="none" 
                        stroke="currentColor"
                        strokeWidth="2"
                        strokeLinecap="round" 
                        strokeLinejoin="round"
                      >
                        <path d="M12 19V5M5 12l7-7 7 7"/>
                      </svg>
                    </button>
                  )}
                </div>
              </div>
              {gameOver && (
                <div className="text-center mt-4 w-full">
                  <div className="text-2xl text-red-600 font-bold mb-4">Game Over!</div>
                  <button
                    onClick={() => setIsPlaying(false)}
                    className="bg-blue-500 text-white px-6 py-3 rounded-lg text-lg font-semibold hover:bg-blue-600 transition-colors"
                  >
                    Neues Spiel
                  </button>
                </div>
              )}
              <div className="text-sm text-gray-600 md:block hidden">
                Drücke SPACE oder klicke zum Springen
              </div>
              <div className="text-sm text-gray-600 md:hidden">
                Tippe auf den Bildschirm oder nutze den Sprung-Button
              </div>
            </div>
          )}
        </div>

        {/* Leaderboard */}
        <div className="mt-8 bg-white rounded-2xl shadow-sm border border-gray-100 overflow-hidden">
          <div className="p-6">
            <h3 className="text-xl font-bold text-gray-900 mb-4">Bestenliste</h3>
            <div className="overflow-hidden">
              {isLoading ? (
                <div className="text-center py-4">Lade Bestenliste...</div>
              ) : (
                <>
                  <table className="min-w-full divide-y divide-gray-200">
                    <thead className="bg-gray-50">
                      <tr>
                        <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                          Rang
                        </th>
                        <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                          Nickname
                        </th>
                        <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                          Höchste Punktzahl
                        </th>
                        <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                          Spiele gespielt
                        </th>
                      </tr>
                    </thead>
                    <tbody className="bg-white divide-y divide-gray-200">
                      {getCurrentPageItems().map((entry, index) => {
                        const position = ((currentPage - 1) * USERS_PER_PAGE) + index + 1;
                        const trueRank = calculateRank(entry.highestScore, index);
                        const isTopThree = trueRank <= 3;
                        const rankStyles = {
                          1: 'bg-yellow-50 hover:bg-yellow-100 font-bold text-yellow-800',
                          2: 'bg-gray-50 hover:bg-gray-100 font-bold text-gray-700',
                          3: 'bg-orange-50 hover:bg-orange-100 font-bold text-orange-800'
                        };
                        const rowClassName = `${
                          isTopThree 
                            ? rankStyles[trueRank as keyof typeof rankStyles]
                            : entry.nickname === nickname 
                              ? "bg-blue-50 hover:bg-blue-100" 
                              : "hover:bg-gray-50"
                        } transition-colors duration-150 ease-in-out`;

                        return (
                          <tr 
                            key={entry.nickname} 
                            className={rowClassName}
                          >
                            <td className="px-6 py-4 whitespace-nowrap">
                              <div className="flex items-center">
                                <span className={`text-lg font-medium ${isTopThree ? 'mr-2' : ''}`}>
                                  {trueRank}
                                </span>
                                {isTopThree && (
                                  <span className="text-2xl" title={`Platz ${trueRank}`}>
                                    {getMedalEmoji(trueRank)}
                                  </span>
                                )}
                              </div>
                            </td>
                            <td className={`px-6 py-4 whitespace-nowrap text-sm ${isTopThree ? 'font-semibold' : ''}`}>
                              {entry.nickname}
                            </td>
                            <td className={`px-6 py-4 whitespace-nowrap text-sm ${isTopThree ? 'font-semibold' : ''}`}>
                              <div className="flex items-center">
                                <span className={isTopThree ? 'text-lg' : ''}>
                                  {entry.highestScore}
                                </span>
                                {isTopThree && (
                                  <span className="ml-2 px-2 py-1 text-xs rounded-full bg-blue-100 text-blue-800">
                                    Top {trueRank}
                                  </span>
                                )}
                              </div>
                            </td>
                            <td className={`px-6 py-4 whitespace-nowrap text-sm ${isTopThree ? 'font-semibold' : ''}`}>
                              {entry.totalGames}
                            </td>
                          </tr>
                        );
                      })}
                      {leaderboard.length === 0 && (
                        <tr>
                          <td colSpan={4} className="px-6 py-4 text-center text-sm text-gray-500">
                            Noch keine Einträge in der Bestenliste
                          </td>
                        </tr>
                      )}
                    </tbody>
                  </table>
                  <PaginationControls />
                </>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default HorseGame; 