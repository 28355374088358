import React, { useState } from 'react';
import { auth } from '../firebase';
import { signInWithEmailAndPassword, createUserWithEmailAndPassword } from 'firebase/auth';
import { useNavigate } from 'react-router-dom';

const LoginForm: React.FC = () => {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [error, setError] = useState('');
  const [isRegistering, setIsRegistering] = useState(false);
  const navigate = useNavigate();

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    setError('');

    try {
      if (isRegistering) {
        if (password !== confirmPassword) {
          setError('Passwörter stimmen nicht überein');
          return;
        }
        await createUserWithEmailAndPassword(auth, email, password);
      } else {
        await signInWithEmailAndPassword(auth, email, password);
      }
      navigate('/profile');
    } catch (error) {
      setError('Anmeldung fehlgeschlagen. Bitte überprüfen Sie Ihre Eingaben.');
    }
  };

  return (
    <div className="max-w-md w-full mx-auto bg-white rounded-xl shadow-lg p-8">
      {/* Tabs */}
      <div className="flex mb-8">
        <button
          className={`flex-1 py-3 px-4 text-lg font-medium rounded-l-lg ${
            !isRegistering 
              ? 'bg-blue-600 text-white' 
              : 'bg-gray-100 text-gray-500 hover:text-gray-700'
          }`}
          onClick={() => setIsRegistering(false)}
        >
          Anmelden
        </button>
        <button
          className={`flex-1 py-3 px-4 text-lg font-medium rounded-r-lg ${
            isRegistering 
              ? 'bg-blue-600 text-white' 
              : 'bg-gray-100 text-gray-500 hover:text-gray-700'
          }`}
          onClick={() => setIsRegistering(true)}
        >
          Registrieren
        </button>
      </div>

      <form onSubmit={handleSubmit} className="space-y-6">
        {error && (
          <div className="text-red-500 text-sm mb-4">
            {error}
          </div>
        )}
        
        <div>
          <label htmlFor="email" className="block text-lg mb-2 text-gray-700">
            E-Mail
          </label>
          <input
            type="email"
            id="email"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            className="w-full px-4 py-3 rounded-lg border border-gray-300 focus:border-blue-500 focus:outline-none focus:ring-blue-500"
            required
          />
        </div>

        <div>
          <label htmlFor="password" className="block text-lg mb-2 text-gray-700">
            Passwort
          </label>
          <input
            type="password"
            id="password"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
            className="w-full px-4 py-3 rounded-lg border border-gray-300 focus:border-blue-500 focus:outline-none focus:ring-blue-500"
            required
          />
        </div>

        {isRegistering && (
          <div>
            <label htmlFor="confirmPassword" className="block text-lg mb-2 text-gray-700">
              Passwort bestätigen
            </label>
            <input
              type="password"
              id="confirmPassword"
              value={confirmPassword}
              onChange={(e) => setConfirmPassword(e.target.value)}
              className="w-full px-4 py-3 rounded-lg border border-gray-300 focus:border-blue-500 focus:outline-none focus:ring-blue-500"
              required
            />
          </div>
        )}

        {!isRegistering && (
          <div className="text-sm">
            <a href="#" className="text-blue-600 hover:text-blue-500">
              Passwort vergessen?
            </a>
          </div>
        )}

        <button
          type="submit"
          className="w-full py-3 px-4 text-lg font-medium text-white bg-blue-600 hover:bg-blue-700 rounded-lg transition-colors focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500"
        >
          {isRegistering ? 'Registrieren' : 'Anmelden'}
        </button>

        <div className="text-center text-base text-gray-500">
          oder
        </div>

        <button
          type="button"
          onClick={() => {/* Google Sign-In Logik hier */}}
          className="w-full flex justify-center items-center py-3 px-4 text-lg font-medium text-gray-700 bg-white border border-gray-300 rounded-lg hover:bg-gray-50 transition-colors focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500"
        >
          <img
            src="https://www.gstatic.com/firebasejs/ui/2.0.0/images/auth/google.svg"
            alt="Google"
            className="w-5 h-5 mr-3"
          />
          Mit Google anmelden
        </button>
      </form>
    </div>
  );
};

export default LoginForm; 