import React, { useState, useEffect } from 'react';
import confetti from 'canvas-confetti';

interface OnboardingModalProps {
  onComplete: (formData: HorseProfileData) => void;
  isOpen: boolean;
}

interface HorseProfileData {
  horseName: string;
  horseBreed: string;
  horseAge: string;
  horseHeight: string;
  horseWeight: string;
  ownerName: string;
  ownerInfo: string;
  horseDescription: string;
}

// Onboarding Modal - Eine moderne Schnittstelle für die Ersteinrichtung des Pferdeprofils
const OnboardingModal: React.FC<OnboardingModalProps> = ({ onComplete, isOpen }) => {
  const [currentStep, setCurrentStep] = useState(1);
  const [showMessage, setShowMessage] = useState(false);
  const [showFinaleMessage, setShowFinaleMessage] = useState(false);
  const [formData, setFormData] = useState<HorseProfileData>({
    // Basis Informationen - Basic Information
    horseName: '',
    horseBreed: '',
    horseAge: '',
    horseHeight: '',
    horseWeight: '',
    // Besitzer Information - Owner Information
    ownerName: '',
    ownerInfo: '',
    // Zusätzliche Details - Additional Details
    horseDescription: '',
  });

  // Get success message based on step
  const getSuccessMessage = (step: number) => {
    switch(step) {
      case 1:
        return "Freut mich dich kennen zu lernen! 🐎";
      case 2:
        return "Toll, das hilft uns sehr weiter! ✨";
      default:
        return "Super gemacht! 🎉";
    }
  };

  // Confetti Finale Effect
  const triggerFinaleConfetti = () => {
    const duration = 3000;
    const end = Date.now() + duration;

    const colors = ['#2563eb', '#FFD700', '#FFA500'];

    // Create a canvas for the confetti that's always on top
    const myCanvas = document.createElement('canvas');
    myCanvas.style.position = 'fixed';
    myCanvas.style.top = '0';
    myCanvas.style.left = '0';
    myCanvas.style.width = '100%';
    myCanvas.style.height = '100%';
    myCanvas.style.pointerEvents = 'none';
    myCanvas.style.zIndex = '9999';
    document.body.appendChild(myCanvas);

    const myConfetti = confetti.create(myCanvas, {
      resize: true,
      useWorker: true
    });

    (function frame() {
      myConfetti({
        particleCount: 3,
        angle: 60,
        spread: 55,
        origin: { x: 0, y: 0.8 },
        colors: colors
      });
      myConfetti({
        particleCount: 3,
        angle: 120,
        spread: 55,
        origin: { x: 1, y: 0.8 },
        colors: colors
      });

      if (Date.now() < end) {
        requestAnimationFrame(frame);
      } else {
        // Clean up the canvas after the animation
        setTimeout(() => {
          document.body.removeChild(myCanvas);
        }, 2000);
      }
    }());

    // Fire off some final bursts
    setTimeout(() => {
      myConfetti({
        particleCount: 100,
        spread: 70,
        origin: { y: 0.6 },
        colors: colors
      });
    }, duration - 1000);
  };

  const showFinale = () => {
    triggerFinaleConfetti();
    setShowFinaleMessage(true);
    setTimeout(() => {
      setShowFinaleMessage(false);
      // Pass the completed form data back to the parent
      onComplete(formData);
    }, 5000);
  };

  // Handle input changes
  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement | HTMLSelectElement>) => {
    const { name, value } = e.target;
    setFormData(prev => ({
      ...prev,
      [name]: value
    }));
  };

  // Success Message Effect zwischen den Schritten
  const showSuccessMessage = () => {
    setShowMessage(true);
    setTimeout(() => setShowMessage(false), 2000);
  };

  // Handle form submission
  const handleSubmit = (e: React.FormEvent) => {
    e.preventDefault();
    if (currentStep < 3) {
      showSuccessMessage();
      setTimeout(() => {
        setCurrentStep(prev => prev + 1);
      }, 2000);
    } else {
      showFinale();
    }
  };

  return (
    <>
      {isOpen && (
        <div style={{
          position: 'fixed',
          top: 0,
          left: 0,
          right: 0,
          bottom: 0,
          backgroundColor: 'rgba(0, 0, 0, 0.75)',
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          zIndex: 1000,
          animation: 'fadeIn 0.3s ease-out'
        }}>
          {showMessage && (
            <div className="success-message-container">
              <div className="success-message">
                {getSuccessMessage(currentStep)}
              </div>
            </div>
          )}
          {showFinaleMessage && (
            <div className="success-message-container">
              <div className="finale-message">
                Perfekt! Mit diesen Informationen können wir dir jetzt noch persönlichere und maßgeschneiderte Beratung anbieten! 🎯✨
              </div>
            </div>
          )}
          <div className="modal-content" style={{
            backgroundColor: 'white',
            borderRadius: '12px',
            padding: '2rem',
            maxWidth: '500px',
            width: '90%',
            boxShadow: '0 8px 20px rgba(0, 0, 0, 0.2)',
            position: 'relative'
          }}>
            <div style={{ textAlign: 'center', marginBottom: '1.5rem' }}>
              <h2 style={{ 
                fontSize: '2rem', 
                fontWeight: 'bold',
                marginBottom: '0.75rem'
              }}>Pferdeprofil erstellen</h2>
              <p style={{ 
                color: '#2563eb',
                fontSize: '1.1rem',
                fontWeight: '500'
              }}>Schritt {currentStep} von 3</p>
            </div>

            <div className="step-dots">
              {[1, 2, 3].map(step => (
                <div
                  key={step}
                  className={`step-dot ${
                    step === currentStep ? 'active' : step < currentStep ? 'completed' : ''
                  }`}
                />
              ))}
            </div>

            <form onSubmit={handleSubmit} style={{ display: 'flex', flexDirection: 'column', gap: '1.5rem' }}>
              {/* Schritt 1: Basis Informationen - Step 1: Basic Information */}
              {currentStep === 1 && (
                <div>
                  <div>
                    <label style={{ 
                      display: 'block',
                      marginBottom: '0.5rem',
                      fontWeight: '500'
                    }}>
                      Name des Pferdes <span style={{ color: '#ff0000' }}>*</span>
                    </label>
                    <input
                      type="text"
                      name="horseName"
                      value={formData.horseName}
                      onChange={handleInputChange}
                      placeholder="Name deines Pferdes"
                      style={{
                        width: '100%',
                        padding: '0.75rem',
                        border: '1px solid #ddd',
                        borderRadius: '4px',
                        fontSize: '1rem'
                      }}
                      required
                    />
                  </div>
                  <div style={{ marginTop: '1rem' }}>
                    <label style={{ 
                      display: 'block',
                      marginBottom: '0.5rem',
                      fontWeight: '500'
                    }}>
                      Rasse
                    </label>
                    <select
                      name="horseBreed"
                      value={formData.horseBreed}
                      onChange={handleInputChange}
                      style={{
                        width: '100%',
                        padding: '0.75rem',
                        border: '1px solid #ddd',
                        borderRadius: '4px',
                        fontSize: '1rem'
                      }}
                    >
                      <option value="">Rasse auswählen</option>
                      <option value="Vollblut">Vollblut</option>
                      <option value="Warmblut">Warmblut</option>
                      <option value="Pony">Pony</option>
                      <option value="Kaltblut">Kaltblut</option>
                      <option value="Araber">Araber</option>
                    </select>
                  </div>
                </div>
              )}

              {/* Schritt 2: Maße und Alter - Step 2: Measurements and Age */}
              {currentStep === 2 && (
                <div>
                  <div className="grid grid-cols-2 gap-4">
                    <div>
                      <label style={{ 
                        display: 'block',
                        marginBottom: '0.5rem',
                        fontWeight: '500'
                      }}>
                        Alter
                      </label>
                      <input
                        type="number"
                        name="horseAge"
                        value={formData.horseAge}
                        onChange={handleInputChange}
                        placeholder="z.B. 12"
                        min="0"
                        max="40"
                        style={{
                          width: '100%',
                          padding: '0.75rem',
                          border: '1px solid #ddd',
                          borderRadius: '4px',
                          fontSize: '1rem'
                        }}
                      />
                    </div>
                    <div>
                      <label style={{ 
                        display: 'block',
                        marginBottom: '0.5rem',
                        fontWeight: '500'
                      }}>
                        Stockmaß (cm)
                      </label>
                      <input
                        type="number"
                        name="horseHeight"
                        value={formData.horseHeight}
                        onChange={handleInputChange}
                        placeholder="z.B. 165"
                        style={{
                          width: '100%',
                          padding: '0.75rem',
                          border: '1px solid #ddd',
                          borderRadius: '4px',
                          fontSize: '1rem'
                        }}
                      />
                    </div>
                  </div>
                  <div style={{ marginTop: '1rem' }}>
                    <label style={{ 
                      display: 'block',
                      marginBottom: '0.5rem',
                      fontWeight: '500'
                    }}>
                      Gewicht (kg)
                    </label>
                    <input
                      type="number"
                      name="horseWeight"
                      value={formData.horseWeight}
                      onChange={handleInputChange}
                      placeholder="z.B. 500"
                      style={{
                        width: '100%',
                        padding: '0.75rem',
                        border: '1px solid #ddd',
                        borderRadius: '4px',
                        fontSize: '1rem'
                      }}
                    />
                  </div>
                </div>
              )}

              {/* Schritt 3: Besitzer & Beschreibung - Step 3: Owner & Description */}
              {currentStep === 3 && (
                <div>
                  <div>
                    <label style={{ 
                      display: 'block',
                      marginBottom: '0.5rem',
                      fontWeight: '500'
                    }}>
                      Dein Name
                    </label>
                    <input
                      type="text"
                      name="ownerName"
                      value={formData.ownerName}
                      onChange={handleInputChange}
                      placeholder="Dein Name"
                      style={{
                        width: '100%',
                        padding: '0.75rem',
                        border: '1px solid #ddd',
                        borderRadius: '4px',
                        fontSize: '1rem'
                      }}
                    />
                  </div>
                  <div style={{ marginTop: '1rem' }}>
                    <label style={{ 
                      display: 'block',
                      marginBottom: '0.5rem',
                      fontWeight: '500'
                    }}>
                      Über dich
                    </label>
                    <textarea
                      name="ownerInfo"
                      value={formData.ownerInfo}
                      onChange={handleInputChange}
                      placeholder="Deine Reiterfahrung, Ziele, besondere Interessen etc."
                      style={{
                        width: '100%',
                        padding: '0.75rem',
                        border: '1px solid #ddd',
                        borderRadius: '4px',
                        fontSize: '1rem',
                        minHeight: '100px'
                      }}
                    />
                  </div>
                  <div style={{ marginTop: '1rem' }}>
                    <label style={{ 
                      display: 'block',
                      marginBottom: '0.5rem',
                      fontWeight: '500'
                    }}>
                      Über dein Pferd
                    </label>
                    <textarea
                      name="horseDescription"
                      value={formData.horseDescription}
                      onChange={handleInputChange}
                      placeholder="Beschreibe dein Pferd (Temperament, besondere Bedürfnisse etc.)"
                      style={{
                        width: '100%',
                        padding: '0.75rem',
                        border: '1px solid #ddd',
                        borderRadius: '4px',
                        fontSize: '1rem',
                        minHeight: '100px'
                      }}
                    />
                  </div>
                </div>
              )}

              <div style={{ 
                display: 'flex',
                justifyContent: 'space-between',
                marginTop: '2rem'
              }}>
                <button
                  type="button"
                  onClick={() => onComplete(formData)}
                  style={{
                    padding: '0.75rem 1.5rem',
                    backgroundColor: '#666',
                    color: 'white',
                    border: 'none',
                    borderRadius: '8px',
                    cursor: 'pointer',
                    fontSize: '1rem',
                    fontWeight: '500'
                  }}
                >
                  Schließen
                </button>
                <button
                  type="submit"
                  style={{
                    padding: '0.75rem 2rem',
                    backgroundColor: '#2563eb',
                    color: 'white',
                    border: 'none',
                    borderRadius: '8px',
                    cursor: 'pointer',
                    fontSize: '1rem',
                    fontWeight: '500',
                    boxShadow: '0 2px 4px rgba(37, 99, 235, 0.2)',
                    opacity: currentStep === 3 ? '1' : '1'
                  }}
                >
                  {currentStep === 3 ? 'Fertig' : 'Weiter'}
                </button>
              </div>
            </form>
          </div>
        </div>
      )}
      <style>
        {`
          @keyframes fadeIn {
            from { opacity: 0; }
            to { opacity: 1; }
          }

          @keyframes modalSlideIn {
            0% { transform: scale(0.8) translateY(40px); opacity: 0; }
            100% { transform: scale(1) translateY(0); opacity: 1; }
          }

          .modal-content {
            animation: modalSlideIn 0.4s cubic-bezier(0.16, 1, 0.3, 1) forwards;
          }

          .success-message-container {
            position: fixed;
            top: 0;
            left: 0;
            right: 0;
            bottom: 0;
            display: flex;
            justify-content: center;
            align-items: center;
            z-index: 2000;
            pointer-events: none;
          }

          .success-message {
            background-color: white;
            color: #333;
            padding: 16px 32px;
            border-radius: 12px;
            font-size: 1.1rem;
            font-weight: 500;
            box-shadow: 0 4px 15px rgba(0, 0, 0, 0.15);
            animation: messagePopup 2s cubic-bezier(0.16, 1, 0.3, 1) forwards;
            transform-origin: center;
          }

          .finale-message {
            background-color: white;
            color: #2563eb;
            padding: 20px 40px;
            border-radius: 12px;
            font-size: 1.2rem;
            font-weight: 600;
            box-shadow: 0 4px 20px rgba(37, 99, 235, 0.25);
            animation: finalePopup 5s cubic-bezier(0.16, 1, 0.3, 1) forwards;
            transform-origin: center;
            text-align: center;
            max-width: 600px;
            line-height: 1.5;
            z-index: 10000;
          }

          @keyframes messagePopup {
            0% { transform: translateY(40px) scale(0.8); opacity: 0; }
            15% { transform: translateY(0) scale(1.1); opacity: 1; }
            25% { transform: translateY(0) scale(1); opacity: 1; }
            85% { transform: translateY(0) scale(1); opacity: 1; }
            100% { transform: translateY(0) scale(0.8); opacity: 0; }
          }

          @keyframes finalePopup {
            0% { transform: translateY(40px) scale(0.8); opacity: 0; }
            10% { transform: translateY(0) scale(1.1); opacity: 1; }
            20% { transform: translateY(0) scale(1); opacity: 1; }
            90% { transform: translateY(0) scale(1); opacity: 1; }
            100% { transform: translateY(0) scale(0.8); opacity: 0; }
          }

          .step-dots {
            display: flex;
            gap: 8px;
            justify-content: center;
            margin: 20px 0;
          }

          .step-dot {
            width: 10px;
            height: 10px;
            border-radius: 50%;
            background-color: #e5e7eb;
            transition: all 0.3s ease;
          }

          .step-dot.active {
            background-color: #2563eb;
            transform: scale(1.2);
          }

          .step-dot.completed {
            background-color: #2563eb;
            opacity: 0.5;
          }
        `}
      </style>
    </>
  );
};

export default OnboardingModal;
