import ReactGA from 'react-ga4';

// Initialize GA4 with your measurement ID
export const initGA = () => {
  try {
    // Initialize in both production and development, but with different settings
    ReactGA.initialize('G-9N4S3L32D8', {
      // Enable debug mode in development
      testMode: process.env.NODE_ENV !== 'production',
      // Additional configuration options
      gaOptions: {
        debug_mode: process.env.NODE_ENV !== 'production'
      }
    });
    
    console.log(`GA4 initialized in ${process.env.NODE_ENV} mode`);
  } catch (error) {
    console.error('Failed to initialize GA4:', error);
  }
};

// Track page views
export const trackPageView = (page: string) => {
  try {
    ReactGA.send({ hitType: "pageview", page });
  } catch (error) {
    console.error('Failed to track page view:', error);
  }
};

// Track events
export const trackEvent = (category: string, action: string, label?: string) => {
  try {
    ReactGA.event({
      category,
      action,
      label
    });
  } catch (error) {
    console.error('Failed to track event:', error);
  }
};

// Track errors
export const trackError = (error: Error, componentStack: string) => {
  try {
    ReactGA.event({
      category: 'Error',
      action: error.name,
      label: `${error.message} | Stack: ${componentStack}`
    });
  } catch (error) {
    console.error('Failed to track error:', error);
  }
};

// Track user properties
export const setUserProperties = (properties: { [key: string]: any }) => {
  try {
    ReactGA.set(properties);
  } catch (error) {
    console.error('Failed to set user properties:', error);
  }
}; 