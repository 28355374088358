import React, { useState, useEffect } from 'react';
import { useNavigate, Link } from 'react-router-dom';
import { Brain, Shield, MessageCircle, Mail, LogIn, Clock, Target, ThumbsUp } from 'lucide-react';
import { auth, db } from '../firebase';
import { 
  signInWithEmailAndPassword, 
  createUserWithEmailAndPassword,
  GoogleAuthProvider,
  signInWithPopup
} from 'firebase/auth';
import { 
  collection, 
  addDoc, 
  serverTimestamp,
  increment,
  doc,
  setDoc,
  updateDoc,
  getDoc
} from 'firebase/firestore';
import { trackEvent, trackPageView } from '../services/analytics';
import { sendMessage, sendDemoMessage } from '../services/chat';
import ReactMarkdown from 'react-markdown';

const WelcomeScreen: React.FC = () => {
  const [showEmailForm, setShowEmailForm] = useState(false);
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [error, setError] = useState('');
  const [isRegistering, setIsRegistering] = useState(false);
  const [agbAccepted, setAgbAccepted] = useState(false);
  const [showDemoChat, setShowDemoChat] = useState(false);
  const [demoResponse, setDemoResponse] = useState<string | null>(null);
  const [isLoading, setIsLoading] = useState(false);
  const [hasUsedDemo, setHasUsedDemo] = useState(false);
  const [loadingText, setLoadingText] = useState('Ich denke nach...');

  // Track page view on component mount
  useEffect(() => {
    trackPageView('/welcome');
  }, []);

  // Add loading text rotation
  useEffect(() => {
    let interval: NodeJS.Timeout;
    if (isLoading) {
      const loadingTexts = [
        'Ich denke nach...',
        'Ich recherchiere...',
        'Ich formuliere...',
        'Ich prüfe die Fakten...',
        'Gleich habe ich eine Antwort...'
      ];
      let index = 0;
      interval = setInterval(() => {
        index = (index + 1) % loadingTexts.length;
        setLoadingText(loadingTexts[index]);
      }, 2000);
    }
    return () => clearInterval(interval);
  }, [isLoading]);

  // Firebase auth handlers
  const handleGoogleSignIn = async () => {
    try {
      const provider = new GoogleAuthProvider();
      await signInWithPopup(auth, provider);
    } catch (error: any) {
      console.error('Google sign-in error:', error);
      if (error.code === 'auth/popup-closed-by-user') {
        setError('Anmeldefenster wurde geschlossen. Bitte versuchen Sie es erneut.');
      } else if (error.code === 'auth/popup-blocked') {
        setError('Pop-up wurde blockiert. Bitte erlauben Sie Pop-ups für diese Seite.');
      } else {
        setError('Google Anmeldung fehlgeschlagen. Bitte versuchen Sie es später erneut.');
      }
    }
  };

  const handleEmailSignIn = async () => {
    try {
      await signInWithEmailAndPassword(auth, email, password);
      setShowEmailForm(false);
    } catch (error: any) {
      console.error('Email sign-in error:', error);
      switch (error.code) {
        case 'auth/user-not-found':
          setError('Kein Konto mit dieser E-Mail-Adresse gefunden.');
          break;
        case 'auth/wrong-password':
          setError('Falsches Passwort. Bitte überprüfen Sie Ihre Eingabe.');
          break;
        case 'auth/invalid-email':
          setError('Ungültige E-Mail-Adresse. Bitte überprüfen Sie Ihre Eingabe.');
          break;
        case 'auth/user-disabled':
          setError('Dieses Konto wurde deaktiviert. Bitte kontaktieren Sie den Support.');
          break;
        case 'auth/too-many-requests':
          setError('Zu viele fehlgeschlagene Versuche. Bitte versuchen Sie es später erneut.');
          break;
        default:
          setError('Anmeldung fehlgeschlagen. Bitte überprüfen Sie Ihre Eingaben.');
      }
    }
  };

  const handleEmailRegister = async () => {
    try {
      // Input validation
      if (!agbAccepted) {
        setError('Bitte akzeptieren Sie unsere AGB, um fortzufahren.');
        return;
      }
      if (password.length < 6) {
        setError('Das Passwort muss mindestens 6 Zeichen lang sein.');
        return;
      }
      if (!/[A-Z]/.test(password)) {
        setError('Das Passwort muss mindestens einen Großbuchstaben enthalten.');
        return;
      }
      if (!/[0-9]/.test(password)) {
        setError('Das Passwort muss mindestens eine Zahl enthalten.');
        return;
      }
      if (!email.includes('@')) {
        setError('Bitte geben Sie eine gültige E-Mail-Adresse ein.');
        return;
      }

      await createUserWithEmailAndPassword(auth, email, password);
      setShowEmailForm(false);
    } catch (error: any) {
      console.error('Registration error:', error);
      // More descriptive error messages for registration
      switch (error.code) {
        case 'auth/email-already-in-use':
          setError('Diese E-Mail-Adresse wird bereits verwendet. Bitte melden Sie sich an oder nutzen Sie die Passwort-Vergessen-Funktion.');
          break;
        case 'auth/invalid-email':
          setError('Ungültige E-Mail-Adresse. Bitte überprüfen Sie Ihre Eingabe.');
          break;
        case 'auth/operation-not-allowed':
          setError('Die Registrierung ist derzeit nicht möglich. Bitte kontaktieren Sie den Support.');
          break;
        case 'auth/weak-password':
          setError('Das Passwort ist zu schwach. Bitte wählen Sie ein stärkeres Passwort (min. 6 Zeichen, Großbuchstaben, Zahlen).');
          break;
        default:
          setError('Registrierung fehlgeschlagen. Bitte versuchen Sie es später erneut.');
      }
    }
  };

  // Updated features array based on Hormozi's value equation
  const valuePropositions = [
    {
      icon: <Target className="w-6 h-6 text-blue-600" />,
      title: "Traumergebnis für dein Pferd",
      description: "Ein gesünderes, leistungsstärkeres und entspannteres Pferd durch maßgeschneiderte Beratung",
      details: "Unsere KI analysiert die individuellen Bedürfnisse deines Pferdes und bietet personalisierte Empfehlungen für Ernährung, Training und Gesundheitsvorsorge. So erreichst du optimale Ergebnisse für das Wohlbefinden deines Pferdes."
    },
    {
      icon: <ThumbsUp className="w-6 h-6 text-blue-600" />,
      title: "Wissenschaftlich fundiert",
      description: "KI-gestützte Expertise basierend auf aktuellen Forschungsergebnissen und bewährten Methoden",
      details: "Wir kombinieren modernste KI-Technologie mit aktuellen wissenschaftlichen Erkenntnissen aus der Pferdewissenschaft. Unsere Empfehlungen basieren auf geprüften Studien und werden kontinuierlich durch Experten validiert."
    },
    {
      icon: <Clock className="w-6 h-6 text-blue-600" />,
      title: "Sofortige Unterstützung",
      description: "24/7 verfügbar - keine Wartezeiten, keine Terminvereinbarungen nötig",
      details: "Erhalte sofortige Antworten auf deine Fragen, egal ob Tag oder Nacht. Keine Wartezeiten auf Termine, keine komplizierte Abstimmung - professionelle Unterstützung genau dann, wenn du sie brauchst."
    }
  ];

  const handleLoginClick = () => {
    trackEvent('User', 'Click Login Button', 'Welcome Screen');
    setShowEmailForm(true);
  };

  const handleRegisterClick = () => {
    trackEvent('User', 'Click Register Button', 'Welcome Screen');
    setShowEmailForm(true);
    setIsRegistering(true);
  };

  const features = [
    {
      icon: <Brain className="w-6 h-6 text-blue-500" />,
      title: 'KI-gestützte Beratung',
      description: 'Erhalten Sie sofort fundierte Antworten auf Ihre Pferdefragen.'
    },
    {
      icon: <Shield className="w-6 h-6 text-blue-500" />,
      title: 'Experten-geprüft',
      description: 'Alle Antworten basieren auf geprüftem Fachwissen.'
    },
    {
      icon: <MessageCircle className="w-6 h-6 text-blue-500" />,
      title: '24/7 verfügbar',
      description: 'Rund um die Uhr für Sie und Ihr Pferd da.'
    }
  ];

  // Beta user message component
  const BetaMessage = () => (
    <div className="text-center mt-8 mb-4 px-4">
      <p className="text-gray-600 text-lg">
        Schreiben Sie uns via Chat unten rechts auf der Seite, um einer von den nächsten 20 Beta-Usern zu werden!
      </p>
    </div>
  );

  // Pre-built questions for demo
  const demoQuestions = [
    "Wie oft sollte ich mein Pferd entwurmen?",
    "Was sind die wichtigsten Anzeichen für Kolik?",
    "Wie erkenne ich, ob mein Pferd Rückenprobleme hat?"
  ];

  // Handle demo question selection
  const handleDemoQuestion = async (question: string) => {
    if (hasUsedDemo) {
      setDemoResponse("Um weitere Fragen zu stellen, registrieren Sie sich bitte für den vollen Zugang. Als registrierter Nutzer können Sie unbegrenzt Fragen stellen! 🌟");
      return;
    }
    
    setIsLoading(true);
    setShowDemoChat(true);
    setDemoResponse(null);
    
    try {
      // Track demo usage in analytics
      trackEvent('Demo', 'Question Selected', question);
      
      // Track demo question in Firestore
      const demoStatsRef = doc(db, 'demoStats', 'questions');
      const demoStatsDoc = await getDoc(demoStatsRef);
      
      if (demoStatsDoc.exists()) {
        // Update existing stats
        await updateDoc(demoStatsRef, {
          [`questions.${question}`]: increment(1),
          totalQuestions: increment(1),
          lastUsed: serverTimestamp()
        });
      } else {
        // Create initial stats document
        await setDoc(demoStatsRef, {
          questions: {
            [question]: 1
          },
          totalQuestions: 1,
          lastUsed: serverTimestamp(),
          createdAt: serverTimestamp()
        });
      }
      
      // Log individual demo question usage
      await addDoc(collection(db, 'demoQuestionLogs'), {
        question,
        timestamp: serverTimestamp(),
        userAgent: navigator.userAgent,
        successful: true
      });
      
      // Get AI response using demo endpoint
      const response = await sendDemoMessage(question);
      
      if (!response.includes('Entschuldigung')) {
        setDemoResponse(response);
        setHasUsedDemo(true);
        localStorage.setItem('hasUsedDemo', 'true');
        trackEvent('Demo', 'Success', question);
      } else {
        trackEvent('Demo', 'Error', response);
        setDemoResponse(response);
        
        // Log error in Firestore
        await addDoc(collection(db, 'demoQuestionLogs'), {
          question,
          timestamp: serverTimestamp(),
          userAgent: navigator.userAgent,
          successful: false,
          error: response
        });
      }
    } catch (error) {
      console.error('Demo chat error:', error);
      const errorMessage = 'Entschuldigung, es ist ein Fehler aufgetreten. Bitte versuchen Sie es später erneut.';
      setDemoResponse(errorMessage);
      trackEvent('Demo', 'Error', error instanceof Error ? error.message : 'Unknown error');
      
      // Log error in Firestore
      await addDoc(collection(db, 'demoQuestionLogs'), {
        question,
        timestamp: serverTimestamp(),
        userAgent: navigator.userAgent,
        successful: false,
        error: error instanceof Error ? error.message : 'Unknown error'
      });
    } finally {
      setIsLoading(false);
    }
  };

  // Check for previous demo usage on component mount
  useEffect(() => {
    const previousDemoUsage = localStorage.getItem('hasUsedDemo');
    if (previousDemoUsage === 'true') {
      setHasUsedDemo(true);
    }
  }, []);

  return (
    // Main container with white background and full height
    <div className="min-h-screen bg-white">
      {/* Relative positioning container for proper z-index stacking */}
      <div className="relative">
        {/* Content width constraint using Tailwind's max-width utilities */}
        <div className="max-w-7xl mx-auto">
          {/* Content wrapper with z-index and padding - Reduced top padding */}
          <div className="relative z-10 pb-8 sm:pb-16 md:pb-20">
            {/* Main content area - Adjusted top margin for better spacing */}
            <main className="mt-4 mx-auto max-w-7xl px-4 sm:mt-6 sm:px-6 md:mt-8 lg:mt-10 lg:px-8">
              <div className="text-center">
                {/* Main heading and description */}
                <h1 className="text-4xl tracking-tight font-extrabold text-gray-900 sm:text-5xl md:text-6xl">
                  <span className="block bg-gradient-to-r from-blue-600 to-blue-500 bg-clip-text text-transparent xl:inline">
                    Sofortige Expertise
                  </span>{' '}
                  <span className="block text-slate-800 xl:inline">
                    für dein Pferd
                  </span>
                </h1>

                <p className="mt-3 text-base text-gray-500 sm:mt-5 sm:text-lg sm:max-w-xl sm:mx-auto md:mt-5 md:text-xl">
                  Ein gesünderes, leistungsstärkeres und entspannteres Pferd - 
                  mit sofortiger, wissenschaftlich fundierter Beratung, wann immer du sie brauchst.
                </p>

                {/* Demo Chat Section - Moved below main heading */}
                <div className="mt-12 max-w-2xl mx-auto px-4 mb-12">
                  <div className="bg-white rounded-2xl shadow-lg p-6">
                    <h2 className="text-xl font-semibold text-gray-900 mb-4">
                      Testen Sie unseren KI-Experten
                    </h2>
                    
                    {!showDemoChat ? (
                      // Question Selection UI
                      <div className="space-y-3">
                        <p className="text-gray-600 mb-4 text-left">
                          Wählen Sie eine Frage aus und erleben Sie die Qualität unserer KI-Beratung:
                        </p>
                        {demoQuestions.map((question, index) => (
                          <button
                            key={index}
                            onClick={() => handleDemoQuestion(question)}
                            disabled={hasUsedDemo}
                            className={`w-full text-left p-3 rounded-xl border border-gray-200 
                                     hover:border-blue-300 hover:bg-blue-50 transition-all
                                     text-gray-700 hover:text-blue-600
                                     ${hasUsedDemo ? 'opacity-50 cursor-not-allowed' : ''}`}
                          >
                            {question}
                          </button>
                        ))}
                        {hasUsedDemo && (
                          <p className="text-sm text-blue-600 mt-4 text-left">
                            Sie haben die Demo bereits genutzt. Registrieren Sie sich für den vollen Zugang! 🌟
                          </p>
                        )}
                      </div>
                    ) : (
                      // Response Display UI
                      <div className="space-y-4">
                        {isLoading ? (
                          <div className="flex flex-col items-center justify-center py-8 space-y-4">
                            <div className="animate-spin rounded-full h-8 w-8 border-b-2 border-blue-600"></div>
                            <p className="text-gray-600 text-sm animate-pulse">
                              {loadingText}
                            </p>
                          </div>
                        ) : demoResponse && (
                          <div className="prose prose-blue max-w-none text-left">
                            <ReactMarkdown>
                              {demoResponse}
                            </ReactMarkdown>
                          </div>
                        )}
                        
                        {/* Call-to-action after response */}
                        {demoResponse && (
                          <div className="mt-6 pt-4 border-t border-gray-100">
                            <button
                              onClick={() => setShowEmailForm(true)}
                              className="w-full py-3 px-4 bg-blue-600 text-white rounded-xl
                                       hover:bg-blue-700 transition-colors"
                            >
                              Jetzt registrieren
                            </button>
                          </div>
                        )}
                      </div>
                    )}
                  </div>
                </div>

                {/* Auth Buttons Section */}
                <div className="mt-8 flex justify-center w-full">
                  <div className="flex flex-col items-center gap-4 w-full max-w-sm">
                    <button
                      onClick={handleGoogleSignIn}
                      className="w-full flex items-center justify-center px-6 py-4 
                               border border-gray-200 rounded-2xl text-gray-700 bg-white 
                               hover:bg-gray-50 transition-all duration-200 text-lg font-normal
                               h-[60px]"
                    >
                      <img 
                        src="/Googlelogo.png" 
                        alt="Google"
                        className="w-6 h-6 mr-3"
                      />
                      <span className="text-lg">Mit Google anmelden</span>
                    </button>

                    <button
                      onClick={() => setShowEmailForm(true)}
                      className="w-full flex items-center justify-center px-6 py-4 
                               bg-[#4263EB] text-white rounded-2xl hover:bg-blue-600 
                               transition-all duration-200 text-lg font-normal
                               h-[60px]"
                    >
                      <Mail className="w-6 h-6 mr-3" />
                      Mit Email anmelden
                    </button>

                    <div className="mt-6 w-full">
                      <div className="relative w-full pt-[177.77%]">
                        <iframe
                          src="https://youtube.com/embed/ZuxZrJAI5pg"
                          className="absolute top-0 left-0 w-full h-full rounded-2xl"
                          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                          allowFullScreen
                        />
                      </div>
                    </div>
                  </div>
                </div>

                {/* Email Auth Modal */}
                {showEmailForm && (
                  <div className="fixed inset-0 z-50 overflow-y-auto bg-black bg-opacity-50 flex items-center justify-center">
                    <div className="bg-white rounded-2xl shadow-lg p-6 w-full max-w-sm mx-4 transform transition-all">
                      {/* Toggle Buttons */}
                      <div className="flex mb-6 bg-gray-100 p-1 rounded-xl">
                        <button
                          className={`flex-1 py-3 px-4 text-lg font-medium rounded-xl transition-all duration-200 
                            ${!isRegistering 
                              ? 'bg-white text-gray-900 shadow-sm' 
                              : 'text-gray-600 hover:text-gray-900'}`}
                          onClick={() => {
                            setIsRegistering(false);
                            setError('');
                          }}
                        >
                          Anmelden
                        </button>
                        <button
                          className={`flex-1 py-3 px-4 text-lg font-medium rounded-xl transition-all duration-200
                            ${isRegistering 
                              ? 'bg-white text-gray-900 shadow-sm' 
                              : 'text-gray-600 hover:text-gray-900'}`}
                          onClick={() => {
                            setIsRegistering(true);
                            setError('');
                          }}
                        >
                          Registrieren
                        </button>
                      </div>

                      {/* Form Header */}
                      <div className="text-center mb-6">
                        <h2 className="text-2xl font-bold text-gray-900">
                          {isRegistering ? 'Konto erstellen' : 'Jetzt loslegen'}
                        </h2>
                        {error && (
                          <div className="mt-3 text-sm text-red-500 bg-red-50 py-2 px-3 rounded-lg">
                            {error}
                          </div>
                        )}
                      </div>

                      {/* Auth Form */}
                      <form onSubmit={(e) => {
                        e.preventDefault();
                        isRegistering ? handleEmailRegister() : handleEmailSignIn();
                      }} className="space-y-4">
                        <div>
                          <input
                            type="email"
                            placeholder="E-Mail"
                            value={email}
                            onChange={(e) => setEmail(e.target.value)}
                            className="w-full px-6 py-4 text-lg text-gray-500 
                                     border border-gray-200 rounded-2xl
                                     focus:outline-none focus:ring-2 focus:ring-blue-100 
                                     focus:border-blue-400 transition-all duration-200
                                     placeholder-gray-400"
                          />
                        </div>
                        <div>
                          <input
                            type="password"
                            placeholder="Passwort"
                            value={password}
                            onChange={(e) => setPassword(e.target.value)}
                            className="w-full px-6 py-4 text-lg text-gray-500 
                                     border border-gray-200 rounded-2xl
                                     focus:outline-none focus:ring-2 focus:ring-blue-100 
                                     focus:border-blue-400 transition-all duration-200
                                     placeholder-gray-400"
                          />
                        </div>

                        {/* AGB Checkbox - only show during registration */}
                        {isRegistering && (
                          <div className="flex items-start mt-4">
                            <div className="flex items-center h-5">
                              <input
                                id="agb"
                                type="checkbox"
                                checked={agbAccepted}
                                onChange={(e) => setAgbAccepted(e.target.checked)}
                                className="w-4 h-4 border border-gray-300 rounded bg-gray-50 
                                         focus:ring-3 focus:ring-blue-300 cursor-pointer"
                              />
                            </div>
                            <label htmlFor="agb" className="ml-2 text-sm text-gray-600">
                              Ich akzeptiere die{' '}
                              <Link 
                                to="/agb" 
                                target="_blank"
                                className="text-blue-600 hover:text-blue-800 underline"
                                onClick={(e) => e.stopPropagation()}
                              >
                                Allgemeinen Geschäftsbedingungen
                              </Link>
                            </label>
                          </div>
                        )}

                        {/* Action Buttons */}
                        <div className="space-y-3 mt-6">
                          <button
                            type="submit"
                            className="w-full py-4 px-6 text-xl font-medium text-white 
                                     bg-[#4263EB] hover:bg-blue-600 rounded-2xl 
                                     transition-all duration-200"
                          >
                            {isRegistering ? 'Registrieren' : 'Anmelden'}
                          </button>
                          <button
                            type="button"
                            onClick={() => {
                              setShowEmailForm(false);
                              setEmail('');
                              setPassword('');
                              setError('');
                              setIsRegistering(false);
                            }}
                            className="w-full py-4 px-6 text-xl font-medium text-gray-600 
                                     bg-gray-100 hover:bg-gray-200 rounded-2xl 
                                     transition-all duration-200"
                          >
                            Abbrechen
                          </button>
                        </div>
                      </form>
                    </div>
                  </div>
                )}

                {/* Value Propositions */}
                <div id="features" className="mt-12 max-w-7xl mx-auto">
                  <div className="grid grid-cols-1 gap-8 sm:grid-cols-2 lg:grid-cols-3">
                    {valuePropositions.map((prop, index) => (
                      <div 
                        key={index}
                        className="relative group bg-white p-6 rounded-lg shadow-md hover:shadow-lg transition-all duration-300 transform hover:-translate-y-1"
                      >
                        <div className="absolute -top-3 left-1/2 transform -translate-x-1/2">
                          <span className="rounded-full inline-flex p-3 bg-blue-50 text-blue-700 ring-4 ring-white">
                            {prop.icon}
                          </span>
                        </div>
                        <div className="mt-8">
                          <h3 className="text-lg font-semibold text-gray-900">
                            {prop.title}
                          </h3>
                          <p className="mt-2 text-sm text-gray-600 leading-relaxed">
                            {prop.description}
                          </p>
                          <div className="mt-4 pt-4 border-t border-gray-100">
                            <p className="text-sm text-gray-500 leading-relaxed">
                              {prop.details}
                            </p>
                          </div>
                        </div>
                      </div>
                    ))}
                  </div>
                </div>
              </div>
            </main>
          </div>
        </div>
      </div>
    </div>
  );
};

export default WelcomeScreen;
