import { auth, db } from '../firebase';
import { doc, getDoc, setDoc, increment, Timestamp } from 'firebase/firestore';

// Interface for user credits
interface UserCredits {
  dailyCredits: number;
  lastRefillDate: Timestamp;
  totalMessagesUsed: number;
}

const DAILY_CREDIT_LIMIT = 10;

/**
 * Initialize or get user credits
 * This function handles:
 * 1. First-time user initialization
 * 2. Daily credit refills
 * 3. Maintaining credit state across sessions
 */
export const initializeUserCredits = async (userId: string): Promise<UserCredits> => {
  const creditsRef = doc(db, 'userCredits', userId);
  
  try {
    // Get existing credits document
    const creditsDoc = await getDoc(creditsRef);
    
    if (!creditsDoc.exists()) {
      // First time user - create new credits document
      const newCredits: UserCredits = {
        dailyCredits: DAILY_CREDIT_LIMIT,
        lastRefillDate: Timestamp.now(),
        totalMessagesUsed: 0
      };
      await setDoc(creditsRef, newCredits);
      return newCredits;
    }

    // Get existing data
    const data = creditsDoc.data() as UserCredits;
    const lastRefill = data.lastRefillDate.toDate();
    const today = new Date();
    
    // Check if it's a new day (comparing UTC dates to avoid timezone issues)
    if (lastRefill.getUTCDate() !== today.getUTCDate() || 
        lastRefill.getUTCMonth() !== today.getUTCMonth() || 
        lastRefill.getUTCFullYear() !== today.getUTCFullYear()) {
      
      // Refill credits for new day
      const updatedCredits: UserCredits = {
        dailyCredits: DAILY_CREDIT_LIMIT,
        lastRefillDate: Timestamp.now(),
        totalMessagesUsed: data.totalMessagesUsed // Preserve total message count
      };
      await setDoc(creditsRef, updatedCredits);
      return updatedCredits;
    }

    // Return existing credits if same day
    return data;
  } catch (error) {
    console.error('Error initializing credits:', error);
    // Return default credits in case of error
    return {
      dailyCredits: DAILY_CREDIT_LIMIT,
      lastRefillDate: Timestamp.now(),
      totalMessagesUsed: 0
    };
  }
};

/**
 * Check if user has credits available
 * Returns true if user can send messages
 */
export const hasCreditsAvailable = async (userId: string): Promise<boolean> => {
  const credits = await initializeUserCredits(userId);
  return credits.dailyCredits > 0;
};

/**
 * Use one credit from user's daily limit
 * Returns false if no credits available
 */
export const useCredit = async (userId: string): Promise<boolean> => {
  const creditsRef = doc(db, 'userCredits', userId);
  const credits = await initializeUserCredits(userId);

  if (credits.dailyCredits <= 0) {
    return false;
  }

  // Update credits atomically
  await setDoc(creditsRef, {
    dailyCredits: increment(-1),
    totalMessagesUsed: increment(1)
  }, { merge: true });

  return true;
};

/**
 * Get user's remaining daily credits
 * Used for UI display
 */
export const getRemainingCredits = async (userId: string): Promise<number> => {
  const credits = await initializeUserCredits(userId);
  return credits.dailyCredits;
}; 