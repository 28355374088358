import { Link, useLocation } from "react-router-dom";
import { MessageCircle, User, Menu, Gamepad2 } from "lucide-react";
import { useState } from "react";

/**
 * BottomTabBar Component
 * Provides navigation for the application with responsive design
 * - Desktop: Shows full navigation bar with logo and navigation items
 * - Mobile: Shows collapsible hamburger menu
 * - Logo and Chat nav item both link to the main chat interface
 */
const BottomTabBar: React.FC = () => {
  // Get current route location for active tab highlighting
  const location = useLocation();
  // State for mobile menu toggle
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  
  // Navigation tab definitions
  // Note: Chat path is '/' to match the main chat UI route
  const tabs = [
    {
      path: '/', // Changed from '/chat' to '/' to match the main chat UI route
      label: 'Chat',
      icon: <MessageCircle className="w-6 h-6" />,
    },
    {
      path: '/profile',
      label: 'Profile',
      icon: <User className="w-6 h-6" />,
    },
    {
      path: '/game',
      label: 'Mini-Spiel',
      icon: <Gamepad2 className="w-6 h-6" />,
    },
  ];

  return (
    <nav className="fixed top-0 left-0 right-0 bg-gray-800 text-white p-4 shadow-md z-50">
      <div className="container mx-auto flex justify-between items-center">
        {/* Logo/Brand - Links to main chat UI */}
        <Link to="/" className="text-2xl font-bold text-white tracking-tight hover:text-blue-300 transition-colors">
          EquiExpert
        </Link>

        {/* Desktop Navigation - Hidden on mobile */}
        <div className="hidden lg:flex items-center space-x-8">
          {tabs.map((tab) => (
            <Link
              key={tab.path}
              to={tab.path}
              className={`flex items-center space-x-2 transition-colors duration-200 hover:text-blue-300 ${
                // Update active state check to handle root path for chat
                (tab.path === '/' ? location.pathname === '/' : location.pathname === tab.path)
                  ? "text-blue-400"
                  : ""
              }`}
            >
              {tab.icon}
              <span>{tab.label}</span>
            </Link>
          ))}
        </div>

        {/* Mobile Menu Toggle - Hidden on desktop */}
        <button 
          onClick={() => setIsMenuOpen(!isMenuOpen)}
          className="lg:hidden hover:text-blue-300 transition-colors"
          aria-label="Toggle navigation menu"
        >
          <Menu size={24} />
        </button>
      </div>

      {/* Mobile Navigation Menu - Hidden on desktop */}
      <div className={`lg:hidden ${isMenuOpen ? 'block' : 'hidden'}`}>
        <div className="px-2 pt-2 pb-3 space-y-1 bg-gray-800 border-t border-gray-700 mt-4">
          {tabs.map((tab) => (
            <Link
              key={tab.path}
              to={tab.path}
              className={`block px-3 py-2 rounded-md transition-colors duration-200 hover:bg-gray-700 ${
                // Update active state check to handle root path for chat
                (tab.path === '/' ? location.pathname === '/' : location.pathname === tab.path)
                  ? "text-blue-400"
                  : ""
              }`}
              onClick={() => setIsMenuOpen(false)}
            >
              <div className="flex items-center space-x-2">
                {tab.icon}
                <span>{tab.label}</span>
              </div>
            </Link>
          ))}
        </div>
      </div>
    </nav>
  );
};

export default BottomTabBar;
