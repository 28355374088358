import { 
  collection, 
  query, 
  orderBy, 
  limit,
  getDocs,
} from 'firebase/firestore';
import { db } from '../firebase';

// Chat service for handling API calls
export interface ChatMessage {
  role: 'user' | 'assistant';
  content: string;
  timestamp: Date;
}

export interface ChatError {
  message: string;
  details?: string;
}

// Add this interface
export interface ChatSuggestions {
  suggestions: string[];
  timestamp: string;
}

// Get the base URL - simplified for Replit environment
const API_BASE_URL = ''; // Empty string to use relative URLs with proxy

// Helper to get the correct API path based on environment
const getApiPath = (path: string) => {
  // Remove leading slash if present
  const cleanPath = path.startsWith('/') ? path.substring(1) : path;
  // In Replit environment, use path without /api prefix
  return window.location.hostname.includes('replit') ? `/${cleanPath}` : `/api/${cleanPath}`;
};

console.log('Using API paths with base:', window.location.hostname.includes('replit') ? '/' : '/api/');

export const sendMessage = async (message: string, userId: string, sessionId: string): Promise<string> => {
  try {
    if (!message?.trim()) {
      throw new Error('Message cannot be empty');
    }

    // Get last N messages from the current chat session
    const lastMessages = await getLastMessages(sessionId, 5);
    
    // Format messages for the API
    const conversationContext = lastMessages.map((msg: ChatMessage) => ({
      role: msg.role,
      content: msg.content
    }));
    
    // Add current message
    conversationContext.push({
      role: 'user',
      content: message
    });

    const apiPath = getApiPath('chat');
    console.log('Sending request to:', apiPath);
    console.log('Request payload:', {
      message,
      userId,
      sessionId,
      contextLength: conversationContext.length
    });

    const response = await fetch(apiPath, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        message: message,
        messages: conversationContext,
        userId: userId,
        sessionId: sessionId
      }),
    });

    if (!response.ok) {
      const errorData = await response.text();
      console.error('API Error Response:', {
        status: response.status,
        statusText: response.statusText,
        data: errorData
      });

      // Handle quota exceeded error specifically
      if (response.status === 429 || errorData.includes('quota')) {
        return 'Entschuldigung, das Nachrichtenlimit wurde erreicht. Bitte versuchen Sie es später erneut.';
      }

      // Handle other errors
      if (response.status === 502) {
        return 'Entschuldigung, der Server ist derzeit nicht erreichbar. Bitte versuchen Sie es später erneut.';
      }

      throw new Error(`API error: ${response.status} ${response.statusText}`);
    }

    const data = await response.json();
    return data.response;
  } catch (error: unknown) {
    const err = error as Error;
    console.error('Error in sendMessage:', {
      name: err.name,
      message: err.message,
      stack: err.stack
    });
    
    // Return a user-friendly error message instead of throwing
    return 'Entschuldigung, es ist ein Fehler aufgetreten. Bitte versuchen Sie es später erneut.';
  }
};

// Helper function to get last N messages
const getLastMessages = async (sessionId: string, count: number): Promise<ChatMessage[]> => {
  try {
    const messagesQuery = query(
      collection(db, `chatSessions/${sessionId}/messages`),
      orderBy('timestamp', 'desc'),
      limit(count)
    );

    const querySnapshot = await getDocs(messagesQuery);
    return querySnapshot.docs
      .map(doc => doc.data() as ChatMessage)
      .reverse();
  } catch (error: unknown) {
    const err = error as Error;
    console.error('Error getting messages:', {
      name: err.name,
      message: err.message,
      stack: err.stack
    });
    return [];
  }
};

// Update demo message function
export const sendDemoMessage = async (message: string): Promise<string> => {
  try {
    const apiPath = getApiPath('chat');
    console.log('Sending demo request to:', apiPath);
    
    // Create the same system message as the real chat with enhanced emoji settings
    const systemMessage = `Du bist EquiExpert, ein KI-Experte für Pferdeverhalten und -psychologie. 
Deine Aufgabe ist es, Menschen bei allen Fragen rund um Pferde zu unterstützen.

WICHTIGE REGELN:
1. Gib KEINE medizinischen Diagnosen oder Behandlungsempfehlungen
2. Bei gesundheitlichen Problemen empfiehl IMMER den Gang zum Tierarzt
3. Bleibe IMMER im Rahmen deiner Expertise (Verhalten, Training, Pflege)
4. Antworte präzise und faktenbasiert
5. Nutze eine freundliche, verständliche Sprache
6. Verwende VIELE passende Emojis in deinen Antworten:
   - 🐎 für Pferde allgemein
   - 🎯 für Training und Ausbildung
   - 🏥 für Gesundheit und Pflege
   - ❤️ für emotionale und soziale Aspekte
   - 💪 für Leistung und Fitness
   - 🌟 für Erfolge und positive Entwicklungen
   - ⚠️ für Warnungen und wichtige Hinweise
   - 🍎 für Fütterung und Ernährung
   - 🌿 für natürliche Haltung
   - 🤝 für Beziehung und Vertrauen
   - 📝 für Tipps und Empfehlungen
   - ✨ für besondere Erfolgsmomente
   - 🎨 für Kreativität im Training
   - 💭 für Verhaltensanalyse
   - 🔍 für genaue Beobachtung
   - 👥 für Herdenverhalten
   - 🌞 für Außenhaltung
   - 🏠 für Stallhaltung
   - 🎠 für Reittraining

DEINE EXPERTISE:
- Körpersprache und Verhalten von Pferden 🐎 💭
- Trainingsmethoden und Ausbildung 🎯 🎨
- Allgemeine Pferdepflege und Haltung 🏥 🌿
- Fütterung und Ernährung (nur allgemeine Empfehlungen) 🍎 💪
- Ausrüstung und Sicherheit ⚠️ 📝
- Mentales Training für Reiter 🌟 ✨

ANTWORTFORMAT:
1. Beginne mit einem passenden Hauptemoji für das Thema
2. Fasse deine Hauptaussage in 1-2 Sätzen zusammen (mit Emojis)
3. Gliedere deine Erklärungen in klare Abschnitte (jeder mit passendem Emoji)
4. Nutze Aufzählungspunkte für bessere Übersichtlichkeit
5. Schließe mit einem praktischen Tipp ab (mit 📝)
6. Markiere wichtige Warnhinweise mit ⚠️
7. Füge mindestens 8-10 passende Emojis in deine Antwort ein
8. Verteile die Emojis sinnvoll im Text, nicht alle am Anfang oder Ende

BEISPIEL-STRUKTUR:
🐎 Hauptaussage mit erstem Emoji
- 💭 Erster Aspekt
- 🌟 Zweiter Aspekt
- 📝 Praktischer Tipp
⚠️ Wichtiger Hinweis`;
    
    const demoPayload = {
      message,
      messages: [
        { role: 'system', content: systemMessage },
        { role: 'user', content: message }
      ],
      userId: 'demo-user',
      sessionId: 'demo-session',
      isDemo: true
    };
    
    console.log('Demo request payload:', demoPayload);
    
    const response = await fetch(apiPath, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(demoPayload)
    });

    if (!response.ok) {
      console.error('Demo API Error:', {
        status: response.status,
        statusText: response.statusText,
        url: response.url
      });
      
      // Return friendly error message for demo users
      return 'Entschuldigung, der Demo-Service ist momentan nicht verfügbar. Bitte versuchen Sie es später erneut oder registrieren Sie sich für den vollen Zugang.';
    }

    const data = await response.json();
    console.log('Demo API Response:', data);
    return data.response;
  } catch (error) {
    console.error('Error in sendDemoMessage:', error);
    // Return user-friendly error instead of throwing
    return 'Entschuldigung, es ist ein Fehler aufgetreten. Bitte versuchen Sie es später erneut oder registrieren Sie sich für den vollen Zugang.';
  }
};

// Function to get chat suggestions
export const getChatSuggestions = async () => {
  // Standard questions that always work
  const standardSuggestions = [
    "Wie erkenne ich, ob es meinem Pferd gut geht? 🏥",
    "Wie kann ich die Kommunikation mit meinem Pferd verbessern? 🎯",
    "Was sind die wichtigsten Punkte bei der täglichen Pferdepflege? 🌟",
    "Welche Anzeichen für Stress sollte ich beachten? 💭",
    "Wie gestalte ich das Training abwechslungsreich? 🎨"
  ];

  return { suggestions: standardSuggestions };
};
  