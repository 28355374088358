import { collection, addDoc, getDocs, query, where } from 'firebase/firestore';
import { db } from '../firebase';

// Types for quiz questions
export interface QuizQuestion {
  id?: string;
  category: string;
  difficulty: string;
  question: string;
  options: string[];
  correctAnswer: string;
  explanation: string;
  imageUrl?: string;
}

// Function to add a batch of questions
export const addQuizQuestions = async (questions: QuizQuestion[]) => {
  try {
    const batch = questions.map(question => 
      addDoc(collection(db, 'quizQuestions'), question)
    );
    await Promise.all(batch);
    return true;
  } catch (error) {
    console.error('Error adding quiz questions:', error);
    return false;
  }
};

// Function to get questions by category and difficulty
export const getQuizQuestions = async (category: string, difficulty: string): Promise<QuizQuestion[]> => {
  try {
    const q = query(
      collection(db, 'quizQuestions'),
      where('category', '==', category),
      where('difficulty', '==', difficulty)
    );
    
    const querySnapshot = await getDocs(q);
    return querySnapshot.docs.map(doc => ({
      id: doc.id,
      ...doc.data()
    })) as QuizQuestion[];
  } catch (error) {
    console.error('Error getting quiz questions:', error);
    return [];
  }
};

// Sample questions data
export const sampleQuestions: QuizQuestion[] = [
  // PFERDEPFLEGE - BEGINNER (5 von 20)
  {
    category: 'horse-care',
    difficulty: 'beginner',
    question: 'Wie oft sollte ein Pferd mindestens geputzt werden?',
    options: ['Täglich', 'Wöchentlich', 'Monatlich', 'Nur wenn es schmutzig ist'],
    correctAnswer: 'Täglich',
    explanation: 'Tägliches Putzen ist wichtig für die Hautgesundheit und ermöglicht es, Verletzungen oder Probleme früh zu erkennen.'
  },
  {
    category: 'horse-care',
    difficulty: 'beginner',
    question: 'Welches Werkzeug wird zur Hufpflege verwendet?',
    options: ['Hufkratzer', 'Bürste', 'Schere', 'Kamm'],
    correctAnswer: 'Hufkratzer',
    explanation: 'Der Hufkratzer ist das wichtigste Werkzeug zur täglichen Hufreinigung und verhindert Fäulnis und Erkrankungen.'
  },
  {
    category: 'horse-care',
    difficulty: 'beginner',
    question: 'Wie oft sollte der Hufschmied das Pferd besuchen?',
    options: ['Alle 6-8 Wochen', 'Einmal im Jahr', 'Alle 6 Monate', 'Nur bei Problemen'],
    correctAnswer: 'Alle 6-8 Wochen',
    explanation: 'Regelmäßige Hufpflege alle 6-8 Wochen ist wichtig für die Gesundheit und Beweglichkeit des Pferdes.'
  },
  {
    category: 'horse-care',
    difficulty: 'beginner',
    question: 'Was gehört zur täglichen Grundpflege eines Pferdes?',
    options: [
      'Hufe auskratzen und Fell bürsten',
      'Mähne flechten',
      'Schweif waschen',
      'Pferd duschen'
    ],
    correctAnswer: 'Hufe auskratzen und Fell bürsten',
    explanation: 'Die tägliche Grundpflege umfasst das Auskratzen der Hufe und Bürsten des Fells, um Gesundheit und Wohlbefinden sicherzustellen.'
  },
  {
    category: 'horse-care',
    difficulty: 'beginner',
    question: 'Welche Bürste verwendet man zuerst beim Putzen?',
    options: [
      'Striegel',
      'Kardätsche',
      'Schwamm',
      'Mähnenbürste'
    ],
    correctAnswer: 'Striegel',
    explanation: 'Der Striegel wird zuerst verwendet, um groben Schmutz und lose Haare zu lösen. Er wird kreisförmig eingesetzt.'
  },

  // PFERDEPFLEGE - MEDIUM (5 von 20)
  {
    category: 'horse-care',
    difficulty: 'medium',
    question: 'Welche Faktoren beeinflussen die Wahl der richtigen Einstreu?',
    options: [
      'Staubentwicklung und Saugfähigkeit',
      'Nur der Preis',
      'Nur die Farbe',
      'Nur die Verfügbarkeit'
    ],
    correctAnswer: 'Staubentwicklung und Saugfähigkeit',
    explanation: 'Die ideale Einstreu sollte staubarm und hochsaugfähig sein, um die Atemwege zu schonen und Hygiene zu gewährleisten.'
  },
  {
    category: 'horse-care',
    difficulty: 'medium',
    question: 'Was ist bei der Pflege eines nassen Pferdes zu beachten?',
    options: [
      'Gründlich mit Schweißmesser abziehen',
      'Sofort in den Stall stellen',
      'In der Sonne trocknen lassen',
      'Mit kaltem Wasser abduschen'
    ],
    correctAnswer: 'Gründlich mit Schweißmesser abziehen',
    explanation: 'Das Schweißmesser entfernt überschüssiges Wasser effektiv und beschleunigt die Trocknung, was Erkältungen vorbeugt.'
  },
  {
    category: 'horse-care',
    difficulty: 'medium',
    question: 'Wie erkennt man einen gut sitzenden Sattel?',
    options: [
      'Gleichmäßige Schweißflecken nach dem Reiten',
      'Der Sattel rutscht nicht',
      'Das Pferd läuft vorwärts',
      'Der Sattel ist teuer'
    ],
    correctAnswer: 'Gleichmäßige Schweißflecken nach dem Reiten',
    explanation: 'Gleichmäßige Schweißflecken zeigen eine gleichmäßige Druckverteilung und damit einen guten Sattelsitz an.'
  },
  {
    category: 'horse-care',
    difficulty: 'medium',
    question: 'Was ist bei der Winterpflege besonders wichtig?',
    options: [
      'Lange Trockenzeiten nach dem Reiten',
      'Häufiges Waschen',
      'Kurz scheren',
      'Weniger putzen'
    ],
    correctAnswer: 'Lange Trockenzeiten nach dem Reiten',
    explanation: 'Im Winter brauchen Pferde nach dem Reiten länger zum Trocknen. Eine vollständige Trocknung ist wichtig, um Erkältungen zu vermeiden.'
  },
  {
    category: 'horse-care',
    difficulty: 'medium',
    question: 'Wie pflegt man richtig die Mähne eines Pferdes?',
    options: [
      'Vorsichtig mit den Fingern durchkämmen',
      'Kräftig bürsten',
      'Täglich waschen',
      'Gar nicht kämmen'
    ],
    correctAnswer: 'Vorsichtig mit den Fingern durchkämmen',
    explanation: 'Vorsichtiges Durchkämmen mit den Fingern schont die Mähne und verhindert das Ausreißen von Haaren.'
  },

  // PFERDEPFLEGE - EXPERT (5 von 20)
  {
    category: 'horse-care',
    difficulty: 'expert',
    question: 'Welche Maßnahmen sind bei der Rehabilitation nach einer Sehnenverletzung wichtig?',
    options: [
      'Kontrollierte Bewegung und Physiotherapie',
      'Sofortige volle Belastung',
      'Dauerhafter Boxenruhe',
      'Normales Training fortsetzen'
    ],
    correctAnswer: 'Kontrollierte Bewegung und Physiotherapie',
    explanation: 'Bei Sehnenverletzungen ist ein ausgewogenes Programm aus kontrollierter Bewegung und Physiotherapie wichtig für die Heilung.'
  },
  {
    category: 'horse-care',
    difficulty: 'expert',
    question: 'Was ist bei der Behandlung von Mauke zu beachten?',
    options: [
      'Hygiene und Trockenheit der Fesseln',
      'Fesseln nass halten',
      'Mit Öl einreiben',
      'Nicht behandeln'
    ],
    correctAnswer: 'Hygiene und Trockenheit der Fesseln',
    explanation: 'Bei Mauke ist absolute Hygiene und das Trockenhalten der Fesseln entscheidend für die Heilung.'
  },
  {
    category: 'horse-care',
    difficulty: 'expert',
    question: 'Wie behandelt man einen Hufabszess fachgerecht?',
    options: [
      'Öffnen und desinfizieren lassen',
      'Weiterreiten wie gewohnt',
      'Nur mit Wasser spülen',
      'Ignorieren bis es besser wird'
    ],
    correctAnswer: 'Öffnen und desinfizieren lassen',
    explanation: 'Ein Hufabszess muss vom Fachmann geöffnet und desinfiziert werden, um eine vollständige Heilung zu ermöglichen.'
  },
  {
    category: 'horse-care',
    difficulty: 'expert',
    question: 'Was ist bei der Pflege eines alten Pferdes (20+ Jahre) besonders zu beachten?',
    options: [
      'Regelmäßige Zahnkontrollen und angepasste Fütterung',
      'Normales Training beibehalten',
      'Keine besondere Pflege nötig',
      'Nur noch in der Box halten'
    ],
    correctAnswer: 'Regelmäßige Zahnkontrollen und angepasste Fütterung',
    explanation: 'Ältere Pferde benötigen besondere Aufmerksamkeit bei Zahngesundheit und Ernährung, um ihre Lebensqualität zu erhalten.'
  },
  {
    category: 'horse-care',
    difficulty: 'expert',
    question: 'Wie erkennt man beginnende Hufrollenentzündung?',
    options: [
      'Verkürzter Schritt und Trachtenfußung',
      'Schwellung am Fesselkopf',
      'Warmer Huf',
      'Übermäßiges Schwitzen'
    ],
    correctAnswer: 'Verkürzter Schritt und Trachtenfußung',
    explanation: 'Erste Anzeichen einer Hufrollenentzündung sind verkürzte Schritte und vermehrtes Fußen auf den Trachten.'
  },

  // GESUNDHEIT - BEGINNER (5 von 20)
  {
    category: 'health',
    difficulty: 'beginner',
    question: 'Was sollte die Basis der Pferdeernährung sein?',
    options: ['Heu', 'Kraftfutter', 'Möhren', 'Äpfel'],
    correctAnswer: 'Heu',
    explanation: 'Heu ist die natürlichste und wichtigste Nahrungsquelle für Pferde und sollte die Basis der täglichen Ernährung bilden.'
  },
  {
    category: 'health',
    difficulty: 'beginner',
    question: 'Wie oft sollte ein Pferd Wasser bekommen?',
    options: [
      'Immer Zugang zu frischem Wasser',
      'Dreimal täglich',
      'Nach dem Reiten',
      'Morgens und abends'
    ],
    correctAnswer: 'Immer Zugang zu frischem Wasser',
    explanation: 'Pferde müssen immer Zugang zu frischem, sauberem Wasser haben, um gesund zu bleiben.'
  },
  {
    category: 'health',
    difficulty: 'beginner',
    question: 'Welche Temperatur hat ein gesundes Pferd?',
    options: [
      '37,5-38,2°C',
      '36,0-37,0°C',
      '39,0-40,0°C',
      '35,5-36,5°C'
    ],
    correctAnswer: '37,5-38,2°C',
    explanation: 'Die normale Körpertemperatur eines gesunden Pferdes liegt zwischen 37,5 und 38,2°C.'
  },
  {
    category: 'health',
    difficulty: 'beginner',
    question: 'Wie erkennt man, ob ein Pferd Durst hat?',
    options: [
      'Hautfaltentest am Hals',
      'Schweifhaltung',
      'Ohrenstellung',
      'Fellfarbe'
    ],
    correctAnswer: 'Hautfaltentest am Hals',
    explanation: 'Der Hautfaltentest am Hals gibt Aufschluss über den Flüssigkeitshaushalt des Pferdes.'
  },
  {
    category: 'health',
    difficulty: 'beginner',
    question: 'Was sind normale Ruhewerte für die Atmung eines Pferdes?',
    options: [
      '8-16 Atemzüge pro Minute',
      '20-30 Atemzüge pro Minute',
      '30-40 Atemzüge pro Minute',
      '40-50 Atemzüge pro Minute'
    ],
    correctAnswer: '8-16 Atemzüge pro Minute',
    explanation: 'Ein gesundes Pferd hat in Ruhe 8-16 Atemzüge pro Minute.'
  },

  // GESUNDHEIT - MEDIUM (5 von 20)
  {
    category: 'health',
    difficulty: 'medium',
    question: 'Welche Anzeichen deuten auf Kolik hin?',
    options: [
      'Scharren, Wälzen und verminderter Kotabsatz',
      'Häufiges Wiehern',
      'Vermehrtes Trinken',
      'Übermäßiges Schlafen'
    ],
    correctAnswer: 'Scharren, Wälzen und verminderter Kotabsatz',
    explanation: 'Kolik ist ein ernstzunehmender Notfall. Typische Anzeichen sind Scharren, Wälzen und verminderter Kotabsatz.'
  },
  {
    category: 'health',
    difficulty: 'medium',
    question: 'Was ist bei der Fütterung von Kraftfutter zu beachten?',
    options: [
      'Kleine Portionen über den Tag verteilt',
      'Einmal täglich eine große Portion',
      'Direkt nach dem Reiten',
      'Zusammen mit viel Wasser'
    ],
    correctAnswer: 'Kleine Portionen über den Tag verteilt',
    explanation: 'Kraftfutter sollte in kleinen Portionen über den Tag verteilt gefüttert werden, um Verdauungsprobleme zu vermeiden.'
  },
  {
    category: 'health',
    difficulty: 'medium',
    question: 'Wie erkennt man eine Hufrehe im Frühstadium?',
    options: [
      'Trippeln und warme Hufe',
      'Vermehrtes Schwitzen',
      'Häufiges Gähnen',
      'Appetitlosigkeit'
    ],
    correctAnswer: 'Trippeln und warme Hufe',
    explanation: 'Erste Anzeichen einer Hufrehe sind Trippeln und ungewöhnlich warme Hufe.'
  },
  {
    category: 'health',
    difficulty: 'medium',
    question: 'Was ist bei der Wurmkur zu beachten?',
    options: [
      'Regelmäßige Kotproben und gezielte Behandlung',
      'Monatliche Behandlung',
      'Nur im Sommer behandeln',
      'Nur bei Symptomen behandeln'
    ],
    correctAnswer: 'Regelmäßige Kotproben und gezielte Behandlung',
    explanation: 'Moderne Wurmbekämpfung basiert auf regelmäßigen Kotproben und gezielter Behandlung statt routinemäßiger Entwurmung.'
  },
  {
    category: 'health',
    difficulty: 'medium',
    question: 'Welche Impfungen sind für Pferde wichtig?',
    options: [
      'Influenza, Tetanus und Herpes',
      'Nur Tetanus',
      'Keine Impfungen nötig',
      'Nur bei Turnierteilnahme'
    ],
    correctAnswer: 'Influenza, Tetanus und Herpes',
    explanation: 'Die wichtigsten Impfungen für Pferde sind gegen Influenza, Tetanus und Herpes.'
  },

  // GESUNDHEIT - EXPERT (5 von 20)
  {
    category: 'health',
    difficulty: 'expert',
    question: 'Was sind die ersten Maßnahmen bei einer Schlagverletzung?',
    options: [
      'Kühlen und Tierarzt rufen',
      'Warm halten',
      'Sofort bewegen',
      'Salbe auftragen'
    ],
    correctAnswer: 'Kühlen und Tierarzt rufen',
    explanation: 'Bei Schlagverletzungen ist sofortiges Kühlen wichtig, um Schwellungen zu minimieren, und ein Tierarzt sollte die Verletzung begutachten.'
  },
  {
    category: 'health',
    difficulty: 'expert',
    question: 'Wie behandelt man eine akute Hufrehe?',
    options: [
      'Kühlung, Hufverband und Tierarzt',
      'Bewegung',
      'Warme Umschläge',
      'Abwarten'
    ],
    correctAnswer: 'Kühlung, Hufverband und Tierarzt',
    explanation: 'Bei akuter Hufrehe sind sofortige Kühlung, ein entlastender Hufverband und tierärztliche Behandlung essentiell.'
  },
  {
    category: 'health',
    difficulty: 'expert',
    question: 'Was sind Anzeichen für EMS (Equines Metabolisches Syndrom)?',
    options: [
      'Fettpolster und Hufrehe-Neigung',
      'Durchfall',
      'Fieber',
      'Appetitlosigkeit'
    ],
    correctAnswer: 'Fettpolster und Hufrehe-Neigung',
    explanation: 'EMS zeigt sich durch charakteristische Fettpolster und eine erhöhte Anfälligkeit für Hufrehe.'
  },
  {
    category: 'health',
    difficulty: 'expert',
    question: 'Wie erkennt man eine Herzerkrankung beim Pferd?',
    options: [
      'Leistungsabfall und Venenpuls',
      'Häufiges Gähnen',
      'Vermehrtes Schwitzen',
      'Durchfall'
    ],
    correctAnswer: 'Leistungsabfall und Venenpuls',
    explanation: 'Herzerkrankungen zeigen sich oft durch Leistungsabfall und sichtbaren Venenpuls am Hals.'
  },
  {
    category: 'health',
    difficulty: 'expert',
    question: 'Was ist bei der Therapie von Arthrose zu beachten?',
    options: [
      'Regelmäßige moderate Bewegung',
      'Absolute Ruhe',
      'Intensive Belastung',
      'Keine Behandlung nötig'
    ],
    correctAnswer: 'Regelmäßige moderate Bewegung',
    explanation: 'Bei Arthrose ist regelmäßige, moderate Bewegung wichtig, um die Gelenke beweglich zu halten und Versteifungen vorzubeugen.'
  }
];

// Function to initialize the database with sample questions
export const initializeQuizDatabase = async () => {
  try {
    // Check if questions already exist
    const existingQuestions = await getDocs(collection(db, 'quizQuestions'));
    if (existingQuestions.empty) {
      // Add sample questions if collection is empty
      await addQuizQuestions(sampleQuestions);
      console.log('Quiz database initialized with sample questions');
      return true;
    }
    console.log('Quiz database already contains questions');
    return false;
  } catch (error) {
    console.error('Error initializing quiz database:', error);
    return false;
  }
}; 